import React from "react";
import Modal from "react-bootstrap/Modal";
import { IoMdClose } from "react-icons/io";
import Button from "react-bootstrap/Button";
import { Col, Form, Row } from "react-bootstrap";
const ProfileUpdateModal = ({
  handleProfileModalClose,
  userProfileModalShow,
  userProfileError,
  userProfile,
  setUserProfile,
  userProfileLoading,
  handleUpdateProfile
}: any) => {
  return <div>
    <Modal
      show={userProfileModalShow}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-filter"
    >
      <Modal.Header className="d-flex justify-content-between">
        <Modal.Title id="contained-modal-title-vcenter">
          Profile Pic Update
        </Modal.Title>
        <IoMdClose
          color="#fff"
          size={20}
          onClick={handleProfileModalClose}
          style={{ cursor: "pointer" }}
        />
      </Modal.Header>
      <Modal.Body>
        <Row className="d-flex gap-1 px-2 py-2">
          <Col lg={12} md={12} xs={12} className="mt-2">
            <Form.Group>
              <Form.Label>Profile Pic</Form.Label>
              <div className="position-relative">
                <Form.Control
                  type="file"
                  placeholder="Enter Email Address"
                  accept="image/*"
                  onChange={(e: any) => setUserProfile(e.target.files[0])}
                />

              </div>
            </Form.Group>
            {
              userProfileError && !userProfile && <div className="text-danger  text-start pt-1">
                Profile Pic is required.
              </div>
            }
          </Col>

        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleProfileModalClose} disabled={userProfileLoading}>
          Close
        </Button>
        <Button
          onClick={handleUpdateProfile}
          disabled={userProfileLoading}>
          {userProfileLoading ? "Updating..." : "Update"}
        </Button>
      </Modal.Footer>
    </Modal>
  </div>;
};

export default ProfileUpdateModal;
