import React, { useContext, useEffect, useRef, useState } from "react";
import { Row, Col, Accordion } from "react-bootstrap";
import {
  calculateTotalFees,
  formatDealSize,
  handleMaxCost,
  handleRoiValues,
  handleSaleValue,
  handleValues,
  useStyles,
} from "../../../helpers";
import { Tooltip } from "@mui/material";
import { ProfitContext } from "../../../context/ProfitContext";
import AlertsMessageQuickInfo from "./alertsMessageQuickInfo";
import {
  getBreakevenClass,
  getBreakevenTooltipValue,
  getBSRClass,
  getBSRValue,
  getEligibleValue,
  getEstSaleClass,
  getEstSaleValue,
  getMaxCostclass,
  getMaxCostTooltipValue,
  getProfitClass,
  getProfitTooltipValue,
  getRoiTooltipValue,
} from "../../../helpers/commanFunction";

interface QuickInfoProps {
  productDetails: any;
  setProductDetails: React.Dispatch<React.SetStateAction<any>>;
  offers: any;
  activeKey: any;
  setOffers: React.Dispatch<React.SetStateAction<any>>;
  roi: any;
  setRoi: React.Dispatch<React.SetStateAction<any>>;
  setActiveKey: React.Dispatch<React.SetStateAction<any>>;
  handleToggle: any;
  totalDiscount: any;
  calculateProfit: any;
  getOffers: any;
  offersFilter: any;
  alerts: any;
  setIsCostPrice: any;
  isCostPrice: any;
  setIsSalePrice: any;
  isSalePrice: any;
  isLoading?: boolean;
}

const QuickInfo = ({
  productDetails,
  setProductDetails,
  offers,
  setOffers,
  roi,
  setRoi,
  activeKey,
  setActiveKey,
  handleToggle,
  totalDiscount,
  calculateProfit,
  getOffers,
  offersFilter,
  alerts,
  setIsCostPrice,
  isCostPrice,
  setIsSalePrice,
  isSalePrice,
  isLoading,
}: QuickInfoProps) => {
  const classes = useStyles();
  const isFirstRender = useRef(true);
  const { setProfit, setSalePrice } =
    useContext(ProfitContext) ??
    (() => {
      throw new Error(
        "ProfitContext is undefined. Ensure the component is within SearchState."
      );
    })();
  const updateDetails = async (updatedObj: any, name: string) => {
    if (name == "costPrice") {
      const updatedValue = handleValues(updatedObj, "quickInfo", totalDiscount);
      setProductDetails(updatedValue);
    } else {
      const updatedValue = handleSaleValue(updatedObj, "quickInfo");
      setProductDetails(updatedValue);
    }
  };
  const profitCalculate = async () => {
    try {
      await calculateProfit(productDetails, "quickInfo", "sale");
    } catch (error) {
      console.log(error);
    }
  };

  const handleCostValues = async () => {
    try {
      const offerValues = await getOffers(
        {},
        false,
        false,
        productDetails?.quickInfo?.costPrice
      );

      setOffers(offerValues);
      const roiValues = handleRoiValues(roi, productDetails, "quickInfo");
      setRoi(roiValues);
      setProfit((productDetails?.quickInfo?.costPrice * 25) / 100);
      setSalePrice(
        productDetails?.quickInfo?.costPrice +
          calculateTotalFees(productDetails?.profitCalculator?.totalFees) +
          (productDetails?.quickInfo?.costPrice * 25) / 100
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleMaxCostValue = () => {
    const updatedValue = handleMaxCost(productDetails, "quickInfo");
    const newlyValues = handleValues(updatedValue, "quickInfo", totalDiscount);
    setProductDetails(newlyValues);
  };

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    const getData = setTimeout(() => {
      profitCalculate();
    }, 1000);

    return () => clearTimeout(getData);
  }, [
    productDetails?.quickInfo?.salePrice,
    productDetails?.quickInfo?.costPrice,
  ]);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    if (productDetails?.quickInfo?.costPrice >= 0) {
      const getData = setTimeout(() => {
        handleCostValues();
      }, 1000);

      return () => clearTimeout(getData);
    }
  }, [productDetails?.quickInfo?.costPrice]);
  useEffect(() => {
    const updatedObj = JSON.parse(JSON.stringify(productDetails));
    updatedObj.quickInfo = {
      ...updatedObj.quickInfo,
      costPrice: Number(isCostPrice),
    };
    setProductDetails(updatedObj);
    updateDetails(updatedObj, "costPrice");
  }, [isCostPrice]);
  useEffect(() => {
    const updatedObj = JSON.parse(JSON.stringify(productDetails));
    updatedObj.quickInfo = {
      ...updatedObj.quickInfo,
      salePrice: Number(isSalePrice),
    };
    setProductDetails(updatedObj);
    updateDetails(updatedObj, "salePrice");
  }, [isSalePrice]);
  const getMessageByLevel = (level: string) => {
    return alerts?.alertLevel?.find((item: any) => item.level === level)
      ?.message;
  };

  const successMessages = getMessageByLevel("success");
  const warningMessages = getMessageByLevel("warning");
  const dangerMessages = getMessageByLevel("danger");

  const shouldShowSuccessOnly = !warningMessages && !dangerMessages;
  const shouldShow = warningMessages?.length > 0 || dangerMessages?.length > 0;
  return (
    <div id="quick-info">
      <Accordion activeKey={activeKey?.quickInfo === true ? "0" : "1"}>
        <Accordion.Item eventKey="0">
          <Accordion.Header onClick={() => handleToggle("quickInfo")}>
            Quick Info
          </Accordion.Header>
          <Accordion.Body className="qi-container">
            <Row className="widget-column">
              <Col lg={6} md={6} sm={6} xs={6} className="panel-col">
                <div className="panel panel-default">
                  <div className="panel-heading">Cost Price</div>
                  <div className="panel-body even_stripe">
                    <div className="input-group">
                      <span className="input-group-addon focus-next-input home-mp-currency-symbol">
                        $
                      </span>
                      <input
                        type="text"
                        id="cost"
                        className="aj-save form-control money-input no-arrows input-cost"
                        name="costPrice"
                        value={isCostPrice}
                        defaultValue={isCostPrice}
                        step="any"
                        readOnly={isLoading}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          let { value } = e.target;
                          setIsCostPrice(value);
                        }}
                        onBlur={() => {
                          let value = String(isCostPrice).trim();
                          if (value === "") {
                            value = "0.00";
                          } else {
                            const [, decimalPart] = value.split(".");
                            if (decimalPart && decimalPart.length >= 2) {
                              value = parseFloat(value).toString();
                            } else {
                              value = parseFloat(value).toFixed(2);
                            }
                          }
                          setIsCostPrice(value);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg={6} md={6} sm={6} xs={6} className="panel-col">
                <div className="panel panel-default ">
                  <div className="panel-heading">Sale Price</div>
                  <div className="panel-body even_stripe">
                    <div className="input-group">
                      <span className="input-group-addon focus-next-input home-mp-currency-symbol">
                        $
                      </span>

                      <input
                        type="text"
                        id="sale_price"
                        className="aj-save form-control money-input no-arrows input-cost"
                        name="salePrice"
                        step="any"
                        value={isSalePrice}
                        defaultValue={isSalePrice}
                        readOnly={isLoading}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          let { value } = e.target;
                          setIsSalePrice(value);
                        }}
                        onBlur={() => {
                          let value = String(isSalePrice).trim();
                          if (value === "") {
                            value = "0.00";
                          } else {
                            const [, decimalPart] = value.split(".");
                            if (decimalPart && decimalPart.length >= 2) {
                              value = parseFloat(value).toString();
                            } else {
                              value = parseFloat(value).toFixed(2);
                            }
                          }
                          setIsSalePrice(value);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg={4} md={6} sm={4} xs={4} className="panel-col">
                <div className="panel panel-default ">
                  <div className="panel-heading">BSR</div>
                  <Tooltip
                    title={
                      <div className="content-tooltip">
                        👍 This meets your BSR criteria.
                      </div>
                    }
                    placement="top"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <div
                      className={`panel-body qi-bsr-pnl ${getBSRClass(
                        productDetails?.quickInfo?.bsr
                      )}`}
                    >
                      <span id="qi-bsr">
                        {getBSRValue(
                          productDetails?.quickInfo?.bsr,
                          formatDealSize
                        )}
                      </span>
                    </div>
                  </Tooltip>
                </div>
              </Col>
              <Col lg={4} md={6} sm={4} xs={4} className="panel-col">
                <div className="panel panel-default ">
                  <div className="panel-heading">Est. Sales</div>
                  <div
                    className={`panel-body qi-estimated-sales-pnl d-flex align-items-center gap-1 justify-content-center ${getEstSaleClass(
                      productDetails?.quickInfo?.estSales
                    )}`}
                  >
                    <Tooltip
                      title={
                        <div className="content-tooltip">
                          <span role="img" aria-label="information">
                            ℹ️
                          </span>
                          We estimate this product sells
                          {productDetails?.quickInfo?.estSales} units per month
                          across all variations.
                        </div>
                      }
                      placement="top"
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <span className="estimated_sales_per_mo">
                        {getEstSaleValue(productDetails?.quickInfo?.estSales)}
                      </span>
                    </Tooltip>
                  </div>
                </div>
              </Col>
              <Col lg={4} md={6} sm={4} xs={4} className="panel-col">
                <div className="panel panel-default ">
                  <div className="panel-heading">Max Cost</div>
                  <Tooltip
                    title={
                      <div className="content-tooltip">
                        {getMaxCostTooltipValue(
                          productDetails?.quickInfo?.costPrice,
                          productDetails?.quickInfo?.maxCost
                        )}
                      </div>
                    }
                    placement="top"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <div
                      className={`panel-body qi-max-cost-pnl ${getMaxCostclass(
                        productDetails?.quickInfo?.costPrice,
                        productDetails?.quickInfo?.maxCost
                      )}`}
                    >
                      <span
                        id="qi-max-cost"
                        className="cost_price cursor-copy"
                        role="button"
                        tabIndex={0}
                        onClick={() => handleMaxCostValue()}
                        onKeyDown={(e) => {
                          if (e.key === "Enter" || e.key === " ") {
                            handleMaxCostValue();
                          }
                        }}
                      >
                        {(() => {
                          const maxCost = Number(
                            productDetails?.quickInfo?.maxCost.replace(/,/g, "")
                          );

                          const formattedCost = Math.abs(maxCost).toFixed(2);
                          return maxCost < 0
                            ? `- $ ${formattedCost}`
                            : `$ ${formattedCost}`;
                        })()}
                      </span>
                    </div>
                  </Tooltip>
                </div>
              </Col>
              <Col lg={6} md={6} sm={6} xs={6} className="panel-col">
                <div className="panel panel-default ">
                  <div className="panel-heading">Eligible</div>
                  <div className="panel-body qi-eligible-pnl criteria-warning">
                    <div className="login-sc-msg warning-text">
                      {getEligibleValue(productDetails?.quickInfo?.eligible)}
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg={6} md={6} sm={6} xs={6} className="panel-col">
                <div className="panel panel-default ">
                  <div className="panel-heading">Alerts</div>
                  <div className="panel-body qi-alerts-pnl criteria-danger-or-warning">
                    {shouldShowSuccessOnly && successMessages?.length > 0 && (
                      <Tooltip
                        title={
                          <div className="content-tooltip">
                            {successMessages.map((msg: any, idx: any) => (
                              <div
                                className="text-start pt-1 pb-1 cursor-pointer"
                                key={`${msg}-${idx}`}
                              >
                                {msg}
                              </div>
                            ))}
                          </div>
                        }
                        placement="top"
                        classes={{ tooltip: classes.tooltip }}
                      >
                        <div
                          style={{
                            backgroundColor: "#155EEF",
                            borderColor: "#155EEF",
                            color: "white",
                            borderRadius: "6px",
                            width: "22px",
                            height: "22px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {successMessages.length}
                        </div>
                      </Tooltip>
                    )}
                    {shouldShow && <AlertsMessageQuickInfo alerts={alerts} />}
                  </div>
                </div>
              </Col>

              <Col lg={3} md={6} sm={3} xs={3} className="panel-col">
                <div className="panel panel-default ">
                  <div className="panel-heading">Profit</div>
                  <Tooltip
                    title={
                      <div className="content-tooltip">
                        {getProfitTooltipValue(
                          productDetails?.quickInfo?.costPrice,
                          productDetails?.quickInfo?.maxCost
                        )}
                      </div>
                    }
                    placement="top"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <div
                      className={`panel-body qi-profit-pnl ${getProfitClass(
                        productDetails?.quickInfo?.costPrice,
                        productDetails?.quickInfo?.maxCost
                      )}`}
                    >
                      <span id="qi-profit">
                        {(() => {
                          const profit = Number(
                            productDetails?.quickInfo?.profit.replace(/,/g, "")
                          );

                          const formattedCost = Math.abs(profit).toFixed(2);
                          return profit < 0
                            ? `- $ ${formattedCost}`
                            : `$ ${formattedCost}`;
                        })()}
                      </span>
                    </div>
                  </Tooltip>
                </div>
              </Col>
              <Col lg={3} md={6} sm={3} xs={3} className="panel-col">
                <div className="panel panel-default ">
                  <div className="panel-heading">Profit&nbsp;%</div>
                  <Tooltip
                    title={
                      <div className="content-tooltip">
                        {getProfitTooltipValue(
                          productDetails?.quickInfo?.costPrice,
                          productDetails?.quickInfo?.maxCost
                        )}
                      </div>
                    }
                    placement="top"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <div
                      className={`panel-body qi-profit-margin-pnl ${getProfitClass(
                        productDetails?.quickInfo?.costPrice,
                        productDetails?.quickInfo?.maxCost
                      )}`}
                    >
                      <span id="qi-profit-margin">
                        {(() => {
                          let profitPercentage =
                            productDetails?.quickInfo?.profitPercentage.replace(
                              /,/g,
                              ""
                            );
                          profitPercentage = isFinite(Number(profitPercentage))
                            ? Number(profitPercentage)
                            : 0;

                          const formattedCost =
                            Math.abs(profitPercentage).toFixed(2);
                          return profitPercentage < 0
                            ? `- ${formattedCost} %`
                            : `${formattedCost} %`;
                        })()}
                      </span>
                    </div>
                  </Tooltip>
                </div>
              </Col>
              <Col lg={3} md={6} sm={3} xs={3} className="panel-col">
                <div className="panel panel-default ">
                  <div className="panel-heading">ROI</div>
                  <Tooltip
                    title={
                      <div className="content-tooltip">
                        {getRoiTooltipValue(
                          productDetails?.quickInfo?.costPrice,
                          productDetails?.quickInfo?.maxCost
                        )}
                      </div>
                    }
                    placement="top"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <div
                      className={`panel-body qi-roi-pnl ${getProfitClass(
                        productDetails?.quickInfo?.costPrice,
                        productDetails?.quickInfo?.maxCost
                      )}`}
                    >
                      <span id="qi-roi">
                        {(() => {
                          let roi = productDetails?.quickInfo?.roi.replace(
                            /,/g,
                            ""
                          );
                          roi = isFinite(Number(roi)) ? Number(roi) : 0;

                          const formattedCost = Math.abs(roi).toFixed(2);
                          return roi < 0
                            ? `- ${formattedCost} %`
                            : `${formattedCost} %`;
                        })()}
                      </span>
                    </div>
                  </Tooltip>
                </div>
              </Col>
              <Col lg={3} md={6} sm={3} xs={3} className="panel-col">
                <div className="panel panel-default ">
                  <div className="panel-heading break-even">Breakeven</div>
                  <Tooltip
                    title={
                      <div className="content-tooltip">
                        {getBreakevenTooltipValue(
                          productDetails?.quickInfo?.costPrice,
                          productDetails?.quickInfo?.salePrice,
                          productDetails?.quickInfo?.breakeven
                        )}
                      </div>
                    }
                    placement="top"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <div
                      className={`panel-body qi-breakeven-pnl ${getBreakevenClass(
                        productDetails?.quickInfo?.salePrice,
                        productDetails?.quickInfo?.salePrice,
                        productDetails?.quickInfo?.breakeven
                      )}`}
                    >
                      <span id="qi-breakeven" className="breakeven csv_price">
                        {(() => {
                          const profit = Number(
                            productDetails?.quickInfo?.profit.replace(/,/g, "")
                          );

                          const formattedCost = Math.abs(profit).toFixed(2);
                          return profit < 0
                            ? `- $ ${formattedCost}`
                            : `$ ${formattedCost}`;
                        })()}
                      </span>
                    </div>
                  </Tooltip>
                </div>
              </Col>
              <Col lg={12} md={6} sm={12} xs={12} className="panel-col">
                <div className="panel panel-default ">
                  <div className="panel-heading">Offers Summary</div>
                  <div className="panel-body qi-offers-pnl even_stripe">
                    <div
                      style={{
                        marginRight: "auto",
                      }}
                    >
                      <div className="qi-offers-summary">
                        <ul className="horizontal-list d-flex align-items-center gap-1 justify-content-start mb-0 p-0">
                          <li>
                            <Tooltip
                              title={
                                <div className="content-tooltip">
                                  Total live offer count available for delivery
                                  to your delivery address or geographical
                                  location
                                </div>
                              }
                              placement="top"
                              classes={{ tooltip: classes.tooltip }}
                            >
                              <div
                                className="seller-description cursor-pointer"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                rel="noopener noreferrer"
                              >
                                <span className="offer-count-bubble tooltips text-bubble plain-box">
                                  <span className="pseudolink dotted-border">
                                    Offers:
                                    <span className="offers-total-cnt">
                                      {offers &&
                                      offers.offersCount !== undefined
                                        ? offers.offersCount
                                        : "-"}
                                    </span>
                                  </span>
                                </span>
                              </div>
                            </Tooltip>
                          </li>

                          {productDetails?.quickInfo?.amazonCount > 0 && (
                            <li className="amz-on-listing">
                              <Tooltip
                                title={
                                  <div className="content-tooltip">
                                    Amazon
                                    <br />
                                    <small>Fulfilled By Amazon</small>
                                  </div>
                                }
                                placement="top"
                                classes={{ tooltip: classes.tooltip }}
                              >
                                <div
                                  className=" cursor-pointer"
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="top"
                                  rel="noopener noreferrer"
                                >
                                  <span className="text-bubble bb-0  ">
                                    <span className="pseudolink dotted-border">
                                      AMZ:
                                      {productDetails?.quickInfo?.amazonCount ||
                                        0}
                                    </span>
                                  </span>
                                </div>
                              </Tooltip>
                            </li>
                          )}
                          <li className="fba-on-listing ">
                            <Tooltip
                              title={
                                <div className="content-tooltip">
                                  Total number of FBA offers
                                </div>
                              }
                              placement="top"
                              classes={{ tooltip: classes.tooltip }}
                            >
                              <div
                                className="cursor-pointer"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                rel="noopener noreferrer"
                              >
                                <span className="fba-on-listing-bubble tooltips text-bubble bb-10  ">
                                  <span className="pseudolink  dotted-border dotted-white">
                                    FBA:
                                    <span className="offers-fba-cnt">
                                      {offers &&
                                      offers.offerCountFBA !== undefined
                                        ? offers.offerCountFBA
                                        : "-"}
                                    </span>
                                  </span>
                                </span>
                              </div>
                            </Tooltip>
                          </li>

                          {offers?.offerCountFBM ? (
                            <li className="text-bubble FBM-7-width">
                              <Tooltip
                                title={
                                  <div className="content-tooltip">
                                    Total number of FBM offers
                                  </div>
                                }
                                placement="top"
                                classes={{ tooltip: classes.tooltip }}
                              >
                                <div
                                  className=" dotted-border cursor-pointer"
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="top"
                                  rel="noopener noreferrer"
                                >
                                  <span className="pseudolink">
                                    FBM:
                                    <span className="offers-fba-cnt">
                                      {offers &&
                                      offers?.offerCountFBM !== undefined
                                        ? offers?.offerCountFBM
                                        : "-"}
                                    </span>
                                  </span>
                                </div>
                              </Tooltip>
                            </li>
                          ) : (
                            <></>
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};

export default QuickInfo;
