import React, {
  createContext,
  useState,
  ReactNode,
  Dispatch,
  SetStateAction,
  useMemo,
} from "react";

interface SearchContextValue {
  searchValue: string;
  setSearchValue: Dispatch<SetStateAction<string>>;
  isGobalFilter: string;
  setIsGobalFilter: Dispatch<SetStateAction<string>>;
  setProfileImage: Dispatch<SetStateAction<string>>;
  profileImage: string;
  setUserFullName: Dispatch<SetStateAction<string>>;
  userFullName: string;
  setUserEmail: Dispatch<SetStateAction<string>>;
  userEmail: string;
}

export const SearchContext = createContext<SearchContextValue | undefined>(
  undefined
);

interface SearchStateProps {
  children: ReactNode;
}

const SearchState: React.FC<SearchStateProps> = ({ children }) => {
  const [searchValue, setSearchValue] = useState<string>("");
  const [isGobalFilter, setIsGobalFilter] = useState<string>("");
  const [profileImage, setProfileImage] = useState<string>("");
  const [userFullName, setUserFullName] = useState<string>("");
  const [userEmail, setUserEmail] = useState<string>("");

  const contextValue = useMemo(
    () => ({
      searchValue,
      setSearchValue,
      isGobalFilter,
      setIsGobalFilter,
      profileImage,
      setProfileImage,
      userFullName,
      setUserFullName,
      userEmail,
      setUserEmail,
    }),
    [searchValue, isGobalFilter, profileImage, userFullName, userEmail]
  );

  return (
    <SearchContext.Provider value={contextValue}>
      {children}
    </SearchContext.Provider>
  );
};

export default SearchState;
