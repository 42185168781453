import { default as httpClient } from "axios";
const baseUrl = process.env.REACT_APP_API_URL;
let authorization: string;
export const token = () => {
  const accessToken = localStorage.getItem("accessToken");
  authorization = "Bearer " + accessToken;
};
const axios = httpClient.create({
  baseURL: baseUrl,
});
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      if (window.location.pathname !== "/") {
        window.location.href = "/";
      }
      localStorage.removeItem("accessToken");
    }
    return Promise.reject(error instanceof Error ? error : new Error(error));
  }
);

export const login = (data: any) => {
  return axios.post(`${baseUrl}/Account/Login`, data);
};
export const forgetPassword = (data: any) => {
  return axios.post(`${baseUrl}/Account/ForgotPassword`, data);
};
export const resetPassword = (data: any) => {
  return axios.post(`${baseUrl}/Account/ResetPassword`, data);
};
export const registeration = (data: any) => {
  return axios.post(`${baseUrl}/Account/Register`, data);
};
export const logout = () => {
  return axios.post(`${baseUrl}/Account/Logout`, null, {
    headers: {
      "Content-Type": "application/json",
      Authorization: authorization,
    },
  });
};
export const uploadFile = (file: any) => {
  return axios.post(`${baseUrl}/ProductUpload/UploadFile`, file, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: authorization,
    },
  });
};
export const productUpload = (request: any) => {
  return axios.post(
    `${baseUrl}/ProductUpload/UploadList`,
    { request },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: authorization,
      },
    }
  );
};
export const addProductUpload = (request: any) => {
  return axios.post(`${baseUrl}/ProductUpload/AddProductUpload`, request, {
    headers: {
      "Content-Type": "application/json",
      Authorization: authorization,
    },
  });
};
export const addProductUploadList = (productId: any) => {
  return axios.get(
    `https://testapi.sellerscout.com/Product/${productId}/ProductDetails`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: authorization,
      },
    }
  );
};
export const getKeepa = (productId: any, country: any, range: any) => {
  return axios.get(
    `${baseUrl}/Keepa/KeepaPriceHistory/${productId}?Country=${country}&Range=${range}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: authorization,
      },
      responseType: "arraybuffer",
    }
  );
};

export const getProductVariations = (productId: any) => {
  return axios.get(`${baseUrl}/Product/${productId}/ProductVariations`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: authorization,
    },
  });
};

export const getAlerts = (productId: any) => {
  return axios.get(`${baseUrl}/Product/${productId}/Alerts`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: authorization,
    },
  });
};

export const productDetails = (object: any) => {
  const {
    productId,
    request,
    IsFavoriteFile,
    selectedOption,
    isShippingRulesEnabled,
    keepaChartDateRange,
    amazonCheck,
    prepFeePerItem,
  } = object;
  return axios.post(`${baseUrl}/ProductUpload/${productId}/Products`, request, {
    headers: {
      "Content-Type": "application/json",
      Authorization: authorization,
      IsFavoriteFile: IsFavoriteFile,
      Fulfillment: selectedOption ? selectedOption : "",
      IsShippingRulesEnabled: isShippingRulesEnabled,
      KeepaChartDateRange: keepaChartDateRange,
      AmazonCheck: amazonCheck,
      ShippingCostPerPound: prepFeePerItem,
    },
  });
};
export const getGraphDetails = (datas: any) => {
  const { country, asin, range } = datas;
  return axios.get(
    `${baseUrl}/Keepa/KeepaPriceHistory/${asin}?Country=${country}&Range=${range}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: authorization,
      },
      responseType: "arraybuffer",
    }
  );
};

export const getSellerOffer = (datas: any) => {
  const { country, asin } = datas;
  return axios.get(
    `https://testapi.sellerscout.com/${country}/${asin}/SellerOffers`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: authorization,
      },
    }
  );
};
export const getSellerVariation = (datas: any) => {
  const { country, asin } = datas;
  return axios.get(
    `https://testapi.sellerscout.com/Product/${asin}/ProductVariations`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: authorization,
        MarketPlace: country,
      },
    }
  );
};
export const getSellerCalculate = (datas: any) => {
  return axios.post(`https://testapi.sellerscout.com/Product/CalculateROI`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: authorization,
    },
  });
};
export const ProductUploadProcess = () => {
  return axios.post(`${baseUrl}/ProductUpload/UploadProcess`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: authorization,
    },
  });
};

export const getFavListSource = (code: any) => {
  return axios.get(`${baseUrl}/Favorite/GetFavoritesBySource/${code}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: authorization,
    },
  });
};
export const postFavListSource = (string: any) => {
  return axios.post(
    `${baseUrl}/Favorite`,
    { favoriteName: string },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: authorization,
      },
    }
  );
};
export const addFavListProduct = (request: any) => {
  return axios.post(`${baseUrl}/Product/AddProductsToFavorite`, request, {
    headers: {
      "Content-Type": "application/json",
      Authorization: authorization,
    },
  });
};
export const getAllFavList = () => {
  return axios.get(`${baseUrl}/Favorite`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: authorization,
    },
  });
};
export const exportFile = (Id: any, request: any, fileName: any) => {
  return axios.post(`${baseUrl}/ProductUpload/${Id}/ExportProducts`, request, {
    headers: {
      "Content-Type": "application/json",
      Authorization: authorization,
      ExportFileName: fileName,
    },
  });
};
export const ProductRoi = (request: any) => {
  return axios.post(`${baseUrl}/Product/CalculateROI`, request, {
    headers: {
      "Content-Type": "application/json",
      Authorization: authorization,
    },
  });
};
export const FavListIsExists = (favoriteName: string) => {
  return axios.get(
    `${baseUrl}/Favorite/IsExists?FavoriteName=${favoriteName}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: authorization,
      },
    }
  );
};

export const getProductInfo = (productId: any) => {
  return axios.get(`${baseUrl}/ProductUpload/${productId}/QuickScan`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: authorization,
    },
  });
};

export const getRanksAndPrices = (productId: any, range: any) => {
  return axios.get(
    `${baseUrl}/Product/Ranks?ASIN=${productId}&Range=${range}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: authorization,
      },
    }
  );
};

export const getSellerOffers = (
  productId: any,
  prime: any,
  live: any,
  cost: any
) => {
  return axios.get(
    `${baseUrl}/Product/${productId}/SellerOffers?Prime=${prime}&Live=${live}&Cost=${cost}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: authorization,
      },
    }
  );
};

export const getNotes = (productId: any) => {
  return axios.get(`${baseUrl}/Product/${productId}/Notes`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: authorization,
    },
  });
};
export const deleteNotes = (noteId: any) => {
  return axios.delete(`${baseUrl}/Product/${noteId}/Note`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: authorization,
    },
  });
};
export const saveNote = (request: any) => {
  return axios.post(`${baseUrl}/Product/Note`, request, {
    headers: {
      "Content-Type": "application/json",
      Authorization: authorization,
    },
  });
};

export const putNotes = (request: any) => {
  return axios.put(`${baseUrl}/Product/Note`, request, {
    headers: {
      "Content-Type": "application/json",
      Authorization: authorization,
    },
  });
};

export const profitCalculate = (request: any) => {
  return axios.post(`${baseUrl}/Product/ProfitCalculate`, request, {
    headers: {
      "Content-Type": "application/json",
      Authorization: authorization,
    },
  });
};

export const getBuyBoxAnalysis = (productId: any, range: any) => {
  return axios.get(
    `${baseUrl}/Product/${productId}/BuyBoxAnalysis?Range=${range}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: authorization,
      },
    }
  );
};

export const getCharts = (productId: any, range: any) => {
  return axios.get(`${baseUrl}/Keepa/Chart/${productId}?Range=${range}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: authorization,
    },
  });
};

export const productHistory = (request: any) => {
  const accessToken = localStorage.getItem("accessToken");
  let authorization = "Bearer " + accessToken;
  return axios.post(`${baseUrl}/productUpload/History`, request, {
    headers: {
      "Content-Type": "application/json",
      Authorization: authorization,
    },
  });
};

export const deleteProductUpload = (request: any) => {
  return axios.delete(`${baseUrl}/ProductUpload/DeleteProductUpload`, {
    data: request,
    headers: {
      "Content-Type": "application/json",
      Authorization: authorization,
    },
  });
};

export const userDetails = () => {
  const accessToken = localStorage.getItem("accessToken");
  let authorization = "Bearer " + accessToken;
  return axios.get(`${baseUrl}/Account/Profile`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: authorization,
    },
  });
};

export const getSubscriptionDetails = () => {
  const accessToken = localStorage.getItem("accessToken");
  let authorization = "Bearer " + accessToken;
  return axios.get(`${baseUrl}/Subscription`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: authorization,
    },
  });
};

export const UpdatePassword = (request: any) => {
  return axios.post(`${baseUrl}/Account/UpdatePassword`, request, {
    headers: {
      "Content-Type": "application/json",
      Authorization: authorization,
    },
  });
};
export const UpdateFullName = (request: any) => {
  return axios.post(`${baseUrl}/Account/UpdateFullName`, request, {
    headers: {
      "Content-Type": "application/json",
      Authorization: authorization,
    },
  });
};
export const UpdateEmail = (request: any) => {
  return axios.post(`${baseUrl}/Account/UpdateEmailId`, request, {
    headers: {
      "Content-Type": "application/json",
      Authorization: authorization,
    },
  });
};
export const UpdateProfile = (request: any) => {
  return axios.post(`${baseUrl}/Account/UploadAvatar`, request, {
    headers: {
      Authorization: authorization,
    },
  });
};
export const resendEmail = (request: any) => {
  return axios.post(`${baseUrl}/Account/ResendEmailVerification`, request, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const verifyEmail = (request: any) => {
  return axios.post(`${baseUrl}/Account/VerifyEmail`, request, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const getUserProfile = (photo: any) => {
  const accessToken = localStorage.getItem("accessToken");
  let authorization = "Bearer " + accessToken;
  return axios.get(`${baseUrl}/Account/ProfilePic?Pic=${photo}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: authorization,
    },
    responseType: "blob",
  });
};
export const getAppConfig = () => {
  const accessToken = localStorage.getItem("accessToken");
  let authorization = "Bearer " + accessToken;
  return axios.get(`${baseUrl}/AppConfig`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: authorization,
    },
  });
};
export const verifyAmazonAuthorize = (request: any) => {
  const accessToken = localStorage.getItem("accessToken");
  let authorization = "Bearer " + accessToken;
  return axios.post(`${baseUrl}/Amazon/Authorize`, request, {
    headers: {
      "Content-Type": "application/json",
      Authorization: authorization,
    },
  });
};

export const productLastAsinSearch = () => {
  const accessToken = localStorage.getItem("accessToken");
  let authorization = "Bearer " + accessToken;
  return axios.get(`${baseUrl}/Product/LastSearch`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: authorization,
    },
  });
};

export const amazonCredentials = () => {
  const accessToken = localStorage.getItem("accessToken");
  let authorization = "Bearer " + accessToken;
  return axios.get(`${baseUrl}/Amazon/Credentials`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: authorization,
    },
  });
};

export const shippingSettings = () => {
  const accessToken = localStorage.getItem("accessToken");
  let authorization = "Bearer " + accessToken;
  return axios.get(`${baseUrl}/ShippingSettings`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: authorization,
    },
  });
};
export const scanSettings = () => {
  const accessToken = localStorage.getItem("accessToken");
  let authorization = "Bearer " + accessToken;
  return axios.get(`${baseUrl}/ScanSettings`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: authorization,
    },
  });
};

export const productUploadSummary = () => {
  const accessToken = localStorage.getItem("accessToken");
  let authorization = "Bearer " + accessToken;
  return axios.post(
    `${baseUrl}/ProductUpload/summary`,
    {},
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: authorization,
      },
    }
  );
};

export const scanSettingsUpdate = (request: any) => {
  const accessToken = localStorage.getItem("accessToken");
  let authorization = "Bearer " + accessToken;
  return axios.post(`${baseUrl}/ScanSettings`, request, {
    headers: {
      "Content-Type": "application/json",
      Authorization: authorization,
    },
  });
};

export const shippingSettingsUpdate = (request: any) => {
  const accessToken = localStorage.getItem("accessToken");
  let authorization = "Bearer " + accessToken;
  return axios.post(`${baseUrl}/ShippingSettings`, request, {
    headers: {
      "Content-Type": "application/json",
      Authorization: authorization,
    },
  });
};
