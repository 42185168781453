import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { sellerscoutLogo } from "../../utils";
import { useNavigate, useLocation } from "react-router-dom";
import { verifyAmazonAuthorize } from "../../Service/services";
import { BiCheckCircle } from "react-icons/bi";
import { MdErrorOutline } from "react-icons/md";
const Authorize = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isAuthorizedError, setIsAuthorizedError] = useState<boolean>(false);
  const [isAuthorizedSuccess, setIsAuthorizedSuccess] =
    useState<boolean>(false);
  const queryParams = new URLSearchParams(location.search);
  const spapiOauthCode = queryParams.get("spapi_oauth_code");
  const state = queryParams.get("state");
  const sellingPartnerId = queryParams.get("selling_partner_id");
  const handleAmazonAuthorize = async () => {
    try {
      if (spapiOauthCode && state && sellingPartnerId) {
        const object = {
          spapi_oauth_code: spapiOauthCode,
          selling_partner_id: sellingPartnerId,
          state: state,
        };
        const response = await verifyAmazonAuthorize(object);
        if (response.status == 200) {
          setIsAuthorizedSuccess(true);
          setTimeout(() => navigate("/panels"), 4000);
        }
      } else {
        navigate("/panels");
      }
    } catch (e: any) {
      if (e?.status == 400) {
        setIsAuthorizedError(true);
        setTimeout(() => navigate("/panels"), 4000);
      }
    }
  };
  useEffect(() => {
    handleAmazonAuthorize();
  }, [spapiOauthCode, state, sellingPartnerId]);
  return (
    <Container fluid>
      <Row
        className=" d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <Col className=" d-flex justify-content-center align-items-center">
          <div className="verification-container">
            <img
              src={sellerscoutLogo}
              alt="Seller Scout Logo"
              width={"220px"}
              height={"21px"}
              className="mb-4"
            />
            {(() => {
              if (isAuthorizedSuccess) {
                return (
                  <>
                    <BiCheckCircle size={100} color="rgb(3 177 117)" />
                    <h2 className="verification-message-success-h2">
                      Amazon Credentials Confirmed!
                    </h2>
                  </>
                );
              }
              if (isAuthorizedError) {
                return (
                  <div>
                    <MdErrorOutline size={100} color="rgb(145 20 68)" />
                    <p
                      id="statusMessage"
                      className="verification-message-success"
                    >
                      Failed to authorize
                    </p>
                  </div>
                );
              }
              return (
                <>
                  <div
                    id="loadingSpinner"
                    className="verification-spinner"
                  ></div>
                  <p id="statusMessage" className="verification-message ">
                    Validating Amazon Credentials...
                  </p>
                </>
              );
            })()}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Authorize;
