import React, { useContext, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "./sidebar";
import Contact from "../Components/Contact";
import Header from "./header";
import { SearchContext } from "../context/searchContext";
import "./layout.css";
import "../App.css";
import Footer from "./footer";

const Main: React.FunctionComponent = () => {
  const [isOpen, setSidebarCollapsed] = useState<boolean>(true);
  const { setSearchValue } =
    useContext(SearchContext) ??
    (() => {
      throw new Error(
        "SearchContext is undefined. Ensure the component is within SearchState."
      );
    })();

  const handleSearch = (searchTerm: any) => {
    localStorage.setItem("ASINID", searchTerm)
    setSearchValue(searchTerm);
  };

  const toggleSidebar = () => {
    setSidebarCollapsed(!isOpen);
  };



 // Handle screen resizing
 const handleResize = () => {
  if (window.innerWidth <= 768) { 
    setSidebarCollapsed(false);
  } else { 
    setSidebarCollapsed(true);
  }
};

useEffect(() => {
  handleResize();
  window.addEventListener("resize", handleResize);
  return () => {
    window.removeEventListener("resize", handleResize);
  };
}, []);


  
  return (
    <div className="app-container">
      <div 
      >
        <Header onSearch={handleSearch} toggleSidebar={toggleSidebar} isOpen={isOpen} />
      </div>
        <Sidebar  isOpen={isOpen} />
      <div className={`outlet-layout ${isOpen ? "shifted" : ""}`}>
        <Outlet />
      </div>
      <div className="contact-query">
        <Contact />
      </div>
      <Footer />
    </div>
  );
};

export default Main;
