import React, { useState, ReactNode } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import styles from "./PricingSlider.module.css";
import { BulkScanner, ProductAnalyzer } from "../../utils";
import { Col, Row } from "react-bootstrap";

interface SliderImage {
  id: number;
  url: string;
  alt: string;
  heading: string;
}

const PricingSlider: React.FC = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const images: SliderImage[] = [
    {
      id: 1,
      url: ProductAnalyzer,
      alt: "Pricing Plan 1",
      heading: "Product Analyzer",
    },
    {
      id: 2,
      url: BulkScanner,
      alt: "Pricing Plan 2",
      heading: "Bulk Scanner",
    },
  ];

  const nextSlide = () => {
    setCurrentIndex(currentIndex === images.length - 1 ? 0 : currentIndex + 1);
  };

  const prevSlide = () => {
    setCurrentIndex(currentIndex === 0 ? images.length - 1 : currentIndex - 1);
  };

  return (
    <section className="pricing-slider">
      <Row>
        <Col className="pt-5">
          <h1 className={`${styles.proTitle}`}>Pricing</h1>
          <p className={`${styles.proText}`}>
            Get on the Pro plan and experience your sales going through the
            roof.
          </p>
        </Col>
      </Row>
      <div className={`${styles.silderLeft} pt-3`}>
        <Row>
          <Col
            xs={2}
            className="d-flex justify-content-center align-items-center"
          >
            <button className={`${styles.arrow}`} onClick={prevSlide}>
              <FaChevronLeft size={20} />
            </button>
          </Col>
          <Col xs={8} className="">
            <div className="slider-image-container">
              <img
                src={images[currentIndex].url}
                alt={images[currentIndex].alt}
                className={styles.sliderImage}
              />
            </div>
          </Col>
          <Col
            xs={2}
            className="d-flex justify-content-center align-items-center"
          >
            <button className={`${styles.arrow}`} onClick={nextSlide}>
              <FaChevronRight size={20} />
            </button>
          </Col>
          <Col>
            <div className="d-flex justify-content-center align-items-center">
              <p className="slider-sub-heading">
                {images[currentIndex].heading}
              </p>
            </div>
            <div className={`${styles.dots} pb-md-5 pb-3`}>
              {images.map((item, index) => (
                <span
                  key={`${item}-${index}`}
                  role="button"
                  tabIndex={0}
                  aria-label={`Go to slide ${index + 1}`}
                  className={`${styles.dot} ${
                    index === currentIndex ? styles.activeDot : ""
                  }`}
                  onClick={() => setCurrentIndex(index)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" || e.key === " ") {
                      setCurrentIndex(index);
                    }
                  }}
                />
              ))}
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default PricingSlider;
