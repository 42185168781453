import React, { useState } from "react";
import { Image } from "react-bootstrap";
import {
  itemScanner,
  filesuploader,
  scanAvailable,
  scanNew,
  scanMembers,
  filesUpload,
  scanDrop,
  itemScan,
} from "../../utils";
import "./index.css";
import UploadFile from "../UploadFIle";

interface YourComponentProps {
  totalCount: number;
  getProductUpload: any;
  isAmazonCredentials: any;
  totalValue: any;
  getSummery?: any;
}

const ScanCards: React.FC<YourComponentProps> = ({
  totalCount,
  getProductUpload,
  isAmazonCredentials,
  totalValue,
  getSummery,
}) => {
  const scans = [
    {
      id: 2,
      count: "Files Uploaded",
      src: filesUpload,
      description: totalValue?.fileUploaded ? totalValue?.fileUploaded : 0,
    },
    {
      id: 3,
      count: "Membership",
      src: scanMembers,
      description: totalValue?.planName,
    },
  ];
  return (
    <div className="pt-2 pb-3 scans-section d-flex flex-column gap-3 flex-md-row justify-content-between row px-1 ">
      <div className="scans-feature-card d-flex flex-column justify-content-center align-items-center  col">
        <Image src={scanDrop} alt="scan" height={40} width={40}></Image>
        <UploadFile
          getProductUpload={getProductUpload}
          isAmazonCredentials={isAmazonCredentials}
          getSummery={getSummery}
        />
        <p className="format-text">Supports: csv, xls & xlsx</p>
      </div>
      <div className="scans-card col">
        <div className="d-flex justify-content-between align-items-center">
          <p className="sub-title">Item Scanned</p>
          <Image src={itemScan} alt="scan" height={40} width={40} />
        </div>
        <h2 className="title">
          {totalValue?.itemScanned ? totalValue?.itemScanned : 0}
        </h2>
      </div>
      {scans.map((scan) => (
        <div key={scan.id} className="scans-card col">
          <div className="d-flex justify-content-between align-items-center">
            <p className="sub-title">{scan.count}</p>
            <Image src={scan.src} alt="scan" height={40} width={40} />
          </div>
          <h2 className="title">{scan.description}</h2>
        </div>
      ))}
    </div>
  );
};

export default ScanCards;
