import React from "react";
import { RiErrorWarningLine } from "react-icons/ri";
import { GoAlertFill } from "react-icons/go";
import {
  getAmazonShareBuyBoxClass,
  getHazmatClass,
  getIPAnalysisClass,
  getPrivateLabelClass,
  getSizeClass,
  getVariationsClass,
} from "../../../helpers/commanFunction";
interface AlertProps {
  data: any;
  alerts: any;
}
const Alerts = ({ data, alerts }: AlertProps) => {
  return (
    <div id="alerts">
      <table className="table table-custom bootstrap-table table-striped table-bordered mb-0">
        <tbody>
          <tr>
            <td style={{ width: " 50%" }}>
              <span className="ranks-description">Amazon Share Buy Box</span>
            </td>
            <td style={{ textAlign: "right", verticalAlign: "middle" }}>
              <span
                className={`d-flex align-items-center justify-content-end ${getAmazonShareBuyBoxClass(
                  alerts
                )}`}
              >
                {alerts?.amazonShareBuyBox ? alerts?.amazonShareBuyBox : "-"}
              </span>
            </td>
          </tr>
          <tr>
            <td style={{ width: " 50%" }}>
              <span className="ranks-description">Private Label</span>
            </td>
            <td style={{ textAlign: "right", verticalAlign: "middle" }}>
              <span
                className={`d-flex align-items-center justify-content-end ${getPrivateLabelClass(
                  alerts
                )}`}
              >
                {alerts?.privateLabel ? (
                  <>
                    <RiErrorWarningLine size={16} className="me-icon me-1" />
                    {alerts?.privateLabel}
                  </>
                ) : (
                  "-"
                )}
              </span>
            </td>
          </tr>
          <tr>
            <td style={{ width: " 50%" }}>
              <span className="ranks-description">IP Analysis</span>
            </td>
            <td style={{ textAlign: "right", verticalAlign: "middle" }}>
              <span
                className={`d-flex align-items-center justify-content-end ${getIPAnalysisClass(
                  alerts
                )}`}
              >
                {alerts?.ipAnalysis?.includes("is known to have IP issues") ? (
                  <>
                    <GoAlertFill size={16} color="#A94442" />
                  </>
                ) : (
                  ""
                )}
                {alerts?.ipAnalysis ? alerts?.ipAnalysis : "-"}
              </span>
            </td>
          </tr>
          <tr>
            <td style={{ width: " 50%" }}>
              <span className="ranks-description">Size</span>
            </td>
            <td style={{ textAlign: "right", verticalAlign: "middle" }}>
              <span
                className={`d-flex align-items-center justify-content-end ${getSizeClass(
                  alerts
                )}`}
              >
                {alerts?.size ? alerts?.size : "-"}
              </span>
            </td>
          </tr>
          {alerts?.fulfillmentFee == 0 && (
            <tr>
              <td style={{ width: " 50%" }}>
                <span className="ranks-description">Fulfillment Fee</span>
              </td>
              <td style={{ textAlign: "right", verticalAlign: "middle" }}>
                <span
                  className={`d-flex align-items-center justify-content-end text-danger`}
                >
                  <GoAlertFill size={16} color="#A94442" />
                  &nbsp; FBA Fee Unknown
                </span>
              </td>
            </tr>
          )}
          <tr>
            <td style={{ width: " 50%" }}>
              <span className="ranks-description">Hazmat</span>
            </td>
            <td style={{ textAlign: "right", verticalAlign: "middle" }}>
              <span
                className={`d-flex align-items-center justify-content-end ${getHazmatClass(
                  alerts?.hazmat
                )}`}
              >
                {alerts?.hazmat ? "Yes" : "No"}
              </span>
            </td>
          </tr>
          <tr>
            <td style={{ width: " 50%" }}>
              <span className="ranks-description">Meltable</span>
            </td>
            <td style={{ textAlign: "right", verticalAlign: "middle" }}>
              <span
                className={`d-flex align-items-center justify-content-end ${getHazmatClass(
                  alerts?.meltable
                )}`}
              >
                {alerts?.meltable ? "Yes" : "No"}
              </span>
            </td>
          </tr>
          <tr>
            <td style={{ width: " 50%" }}>
              <span className="ranks-description">Variations</span>
            </td>
            <td style={{ textAlign: "right", verticalAlign: "middle" }}>
              <span
                className={`d-flex align-items-center justify-content-end ${
                  getVariationsClass(alerts?.variations)
                }`}
              >
                {alerts?.variations ? (
                  <>
                    <RiErrorWarningLine size={16} className="me-icon me-1" />
                    This listing has {alerts?.variations} variations
                  </>
                ) : (
                  "No"
                )}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Alerts;
