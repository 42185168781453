import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { sellerscoutLogo } from "../../utils";
import { BiCheckCircle } from "react-icons/bi";
import { useNavigate, useLocation } from "react-router-dom";
import "./index.css";
import { resendEmail, verifyEmail } from "../../Service/services";
import { MdErrorOutline } from "react-icons/md";
import toast from "react-hot-toast";

const EmailVerify: React.FunctionComponent = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isVerified, setIsVerified] = useState<boolean>(false);
  const [isAlreadyVerified, setIsAlreadyVerified] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const queryParams = new URLSearchParams(location.search);
  const getToken = queryParams.get("token");
  const getEmail = queryParams.get("email");
  const handleVerifyEmail = async () => {
    try {
      if (getToken) {
        const object = {
          emailConfirmedToken: getToken,
        };
        const result = await verifyEmail(object);
        if (result.data == "AlreadyVerified") {
          setIsAlreadyVerified(true);
          setTimeout(() => navigate("/"), 3000);
        } else if (result.data == "Verified") {
          setIsVerified(true);
          setTimeout(() => navigate("/"), 3000);
        }
      } else {
        navigate("/");
      }
    } catch (error: any) {
      setIsVerified(false);
      if (error.status == 400) {
        setIsError(true);
      }
      console.log("error", error.status);
    }
  };
  const handleResendEmailVerification = async () => {
    try {
      if (getEmail) {
        const object = {
          email: getEmail,
          emailConfirmationRedirectUrl: `${process.env.REACT_APP_SERVER_URL}/email-verify?email=${getEmail}&token=`,
        };
        const response = await resendEmail(object);
        console.log(response);
        toast.success("Email sent successfully!");
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    handleVerifyEmail();
  }, [getToken]);
  const renderVerificationMessage = () => {
    if (isVerified) {
      return (
        <div>
          <BiCheckCircle size={100} color="rgb(3 177 117)" />
          <h2 className="verification-message-success-h2">Email Confirmed!</h2>
          <p id="statusMessage" className="verification-message-success">
            Congratulations! Your email has been confirmed and your account is
            ready.
          </p>
        </div>
      );
    } else if (isAlreadyVerified) {
      return (
        <div>
          <BiCheckCircle size={100} color="rgb(3 177 117)" />
          <h2 className="verification-message-success-h2">
            Email Already Confirmed!
          </h2>
          <p id="statusMessage" className="verification-message-success">
            Your email is already confirmed, and your account is ready to use.
          </p>
        </div>
      );
    } else if (isError) {
      return (
        <div>
          <MdErrorOutline size={100} color="rgb(145 20 68)" />
          <p id="statusMessage" className="verification-message-success">
            An error occurred during email verification. You can resend the
            email verification to try again.&nbsp;
            <span
              className="verification-message-error"
              role="button"
              tabIndex={0}
              onClick={handleResendEmailVerification}
              onKeyDown={(e) => {
                if (e.key === "Enter" || e.key === " ") {
                  handleResendEmailVerification();
                }
              }}
            >
              Resend Email
            </span>
          </p>
        </div>
      );
    } else {
      return (
        <>
          <div id="loadingSpinner" className="verification-spinner"></div>
          <p id="statusMessage" className="verification-message">
            Checking your email for verification...
          </p>
        </>
      );
    }
  };
  return (
    <Container fluid>
      <Row
        className=" d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <Col className=" d-flex justify-content-center align-items-center">
          <div className="verification-container">
            <img
              src={sellerscoutLogo}
              alt="Seller Scout Logo"
              width={"220px"}
              height={"21px"}
              className="mb-4"
            />
            {renderVerificationMessage()}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default EmailVerify;
