import React from "react";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import { login } from "../Service/services";
import { FcGoogle } from "react-icons/fc";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
interface GoogleAuthProps {
  isSignUp: boolean;
  setIsGoogleLoading?: any;
}

const GoogleAuth: React.FunctionComponent<GoogleAuthProps> = ({
  isSignUp,
  setIsGoogleLoading,
}) => {
  const navigate = useNavigate();
  const parseJwt = (token: any) => {
    try {
      const base64Url = token.split(".")[1];
      const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map((c) => {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );
      return JSON.parse(jsonPayload);
    } catch (error) {
      console.error("Failed to parse JWT", error);
      return null;
    }
  };
  const handleGoogleLogin = async (response: any) => {
    try {
      const token = response.credential;
      const userInfo = parseJwt(token);

      if (userInfo) {
        setIsGoogleLoading(true);

        const response = await login({
          email: userInfo.email,
          password: "Asdzxc9900!",
          IsExternalLogin: true,
          GoogleSubId: userInfo.sub,
          Provider: "google.com",
          fullName: userInfo.name,
          GooglePicUrl: userInfo.picture,
        });

        if (response.status !== 200) return;

        setIsGoogleLoading(false);
        const {
          haveActiveSubscription,
          accessToken,
          userProfile,
          userInfo: user,
          plan,
          credentials,
        } = response.data;

        if (!haveActiveSubscription) {
          navigate("/plan");
          return;
        }

        const userDetails = {
          ...userProfile,
          fullname: user?.fullName,
          ...plan,
        };

        localStorage.setItem("userProfile", JSON.stringify(userDetails));
        localStorage.setItem("accessToken", accessToken);

        if (user?.photo) {
          localStorage.setItem("ProfilePicture", user.photo);
        }

        if (credentials?.haveAmazonCredentials) {
          window.location.href = "/overview";
        } else {
          localStorage.setItem(
            "AmazonCredentialsModal",
            credentials?.haveAmazonCredentials
          );
          window.location.href = "/panels";
        }
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.ErrorMessage);
      setIsGoogleLoading(false);
    }
  };

  const handleGoogleError = () => {
    toast.error("Google authentication failed.");
  };
  const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
  if (!clientId) {
    throw new Error(
      "Google OAuth client ID is missing. Please check your environment variable."
    );
  }
  return (
    <GoogleOAuthProvider clientId={clientId}>
      <button
        onClick={() => {
          const googleLoginButton = document.querySelector(
            "div[role='button']"
          ) as HTMLElement;
          if (googleLoginButton) {
            googleLoginButton.click();
          }
        }}
        style={{
          backgroundColor: "transparent",
          color: "#000",
          border: "1px solid rgb(0, 0, 0)",
          borderRadius: "12px",
          width: "100%",
          paddingTop: "10px",
          paddingBottom: "10px",
          fontSize: "18px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
          fontWeight: "500",
          gap: "6px",
          transition: "background-color 0.3s ease, color 0.3s ease",
          height: "48px",
          marginTop: "6px",
          fontFamily: "Poppins",
        }}
      >
        <FcGoogle size={25} />{" "}
        {isSignUp ? "Sign up with Google" : "Sign in with Google"}
      </button>
      <div style={{ display: "none" }}>
        <GoogleLogin
          onSuccess={handleGoogleLogin}
          onError={handleGoogleError}
          useOneTap={true}
        />
      </div>
    </GoogleOAuthProvider>
  );
};

export default GoogleAuth;
