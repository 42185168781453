import React, { useContext, useEffect, useRef, useState } from "react";
import { Accordion } from "react-bootstrap";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import {
  calculateTotalFees,
  handleMaxCost,
  handleOfferValues,
  handleRoiValues,
  handleSaleValue,
  handleValues,
  transformKeys,
  useStyles,
} from "../../../helpers";
import { Tooltip } from "@mui/material";
import { ProfitContext } from "../../../context/ProfitContext";
import { GoAlertFill } from "react-icons/go";
import {
  getactiveKeyValue,
  profitCalculatorClosingFee,
  profitCalculatorCost,
  profitCalculatorCostPrice,
  profitCalculatorDiscount,
  profitCalculatorFulfilmentFBMTypeValue,
  profitCalculatorFulfilmentTypeClass,
  profitCalculatorFulfilmentTypeValue,
  profitCalculatorProfit,
  profitCalculatorReferralFeeTooltips,
  profitCalculatorReferralFeeValue,
  profitCalculatorRoiClass,
  profitCalculatorSalePrice,
  profitCalculatorStorageFeeValue,
} from "../../../helpers/commanFunction";

interface ProfitCalculatorProps {
  productDetails: any;
  estimates: any;
  activeKey: any;
  setProductDetails: React.Dispatch<React.SetStateAction<any>>;
  setActiveKey: React.Dispatch<React.SetStateAction<any>>;
  handleToggle: any;
  offers: any;
  setOffers: React.Dispatch<React.SetStateAction<any>>;
  roi: any;
  setRoi: React.Dispatch<React.SetStateAction<any>>;
  totalDiscount: any;
  setFulFillmentType: React.Dispatch<React.SetStateAction<any>>;
  calculateProfit: any;
  referralFee: any;
  getOffers: any;
  offersFilter: any;
  isLoading: boolean;
  setIsStorageFee: any;
  setIsCostPrice: any;
  isCostPrice: any;
  setIsSalePrice: any;
  isSalePrice: any;
}
const ProfitCalculator = ({
  productDetails,
  estimates,
  setProductDetails,
  activeKey,
  setActiveKey,
  handleToggle,
  offers,
  setOffers,
  roi,
  setRoi,
  totalDiscount,
  setFulFillmentType,
  calculateProfit,
  referralFee,
  getOffers,
  offersFilter,
  isLoading,
  setIsStorageFee,
  setIsCostPrice,
  isCostPrice,
  setIsSalePrice,
  isSalePrice,
}: ProfitCalculatorProps) => {
  const isFirstRender = useRef(true);
  const classes = useStyles();
  const { setProfit, setSalePrice } =
    useContext(ProfitContext) ??
    (() => {
      throw new Error(
        "ProfitContext is undefined. Ensure the component is within SearchState."
      );
    })();

  const [isCollapsed1, setIsCollapsed1] = useState(true);
  const [isCollapsed2, setIsCollapsed2] = useState(true);
  const [isCollapsed3, setIsCollapsed3] = useState(true);
  const [isCollapsed4, setIsCollapsed4] = useState(true);
  const toggleCollapse1 = () => {
    setIsCollapsed1(!isCollapsed1);
  };
  const toggleCollapse2 = () => {
    setIsCollapsed2(!isCollapsed2);
  };
  const toggleCollapse3 = () => {
    setIsCollapsed3(!isCollapsed3);
  };
  const toggleCollapse4 = () => {
    setIsCollapsed4(!isCollapsed4);
  };
  let debounceTimer: NodeJS.Timeout;

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    let newValue = value;
    const validPattern = /^\d*\.?\d*$/;
    if (validPattern.test(newValue)) {
      const parts = newValue.split(".");
      if (parts.length === 2 && parts[1].length > 2) {
        newValue = `${parts[0]}.${parts[1].slice(0, 2)}`;
      }
    }
    const updatedObj = JSON.parse(JSON.stringify(productDetails));

    if (name === "storage_Months") {
      setIsStorageFee(newValue);
      updatedObj.profitCalculator = {
        ...updatedObj.profitCalculator,
        [name]: Number(newValue),
        totalFees: {
          ...updatedObj.profitCalculator.totalFees,
        },
      };
    } else {
      updatedObj.profitCalculator = {
        ...updatedObj.profitCalculator,
        [name]: Number(newValue),
      };
    }
    setProductDetails(updatedObj);
    updateDetails(updatedObj, name);
  };

  const updateDetails = async (updatedObj: any, name: string) => {
    if (name == "costPrice") {
      const updatedValue = handleValues(
        updatedObj,
        "profitCalculator",
        totalDiscount
      );
      setProductDetails(updatedValue);
    } else {
      const updatedValue = handleSaleValue(updatedObj, "profitCalculator");
      setProductDetails(updatedValue);
    }
    const offerValues = await handleOfferValues(
      offers,
      updatedObj,
      "profitCalculator"
    );
    setOffers(offerValues);
    const roiValues = handleRoiValues(roi, updatedObj, "profitCalculator");
    setRoi(roiValues);
  };

  const handleMaxCostValue = () => {
    const updatedValue = handleMaxCost(productDetails, "profitCalculator");
    const newlyValues = handleValues(
      updatedValue,
      "profitCalculator",
      totalDiscount
    );

    setProductDetails(newlyValues);
  };

  const handleCheckboxChange = () => {
    const currentType = productDetails?.profitCalculator?.fulfilmentType;
    const newType = currentType === "FBA" ? "FBM" : "FBA";
    setFulFillmentType(newType === "FBA" ? 0 : 1);
    setProductDetails((prevState: any) => ({
      ...prevState,
      profitCalculator: {
        ...prevState.profitCalculator,
        fulfilmentType: newType,
      },
    }));
  };

  const profitCalculate = async (type?: any) => {
    try {
      await calculateProfit(productDetails, "profitCalculator", type);
    } catch (error) {
      console.log(error);
    }
  };

  const handleCostPrices = async () => {
    try {
      const offerValues = await getOffers(
        {},
        false,
        false,
        productDetails?.profitCalculator?.costPrice
      );
      setOffers(offerValues);
      const roiValues = handleRoiValues(
        roi,
        productDetails,
        "profitCalculator"
      );
      setRoi(roiValues);
      setProfit((productDetails?.profitCalculator?.costPrice * 25) / 100);
      setSalePrice(
        productDetails?.profitCalculator?.costPrice +
          calculateTotalFees(productDetails?.profitCalculator?.totalFees) +
          (productDetails?.profitCalculator?.costPrice * 25) / 100
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    const getData = setTimeout(() => {
      profitCalculate();
    }, 500);

    return () => clearTimeout(getData);
  }, [productDetails?.profitCalculator?.FBMCost]);

  useEffect(() => {
    const updatedObj = JSON.parse(JSON.stringify(productDetails));
    updatedObj.profitCalculator = {
      ...updatedObj.profitCalculator,
      salePrice: Number(isSalePrice),
      salePriceInString: Number(isSalePrice).toFixed(2),
    };
    setProductDetails(updatedObj);
    updateDetails(updatedObj, "salePrice");
  }, [isSalePrice]);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    const costPrice = productDetails?.profitCalculator?.costPrice;

    if (costPrice >= 0) {
      const timeoutId = setTimeout(() => {
        handleCostPrices();
      }, 2000);

      return () => clearTimeout(timeoutId);
    }
  }, [productDetails?.profitCalculator?.costPrice]);

  useEffect(() => {
    const updatedObj = JSON.parse(JSON.stringify(productDetails));
    updatedObj.profitCalculator = {
      ...updatedObj.profitCalculator,
      costPrice: Number(isCostPrice),
      costPriceInString: Number(isCostPrice).toFixed(2),
    };
    setProductDetails(updatedObj);
    updateDetails(updatedObj, "costPrice");
  }, [isCostPrice]);
  return (
    <Accordion activeKey={getactiveKeyValue(activeKey?.profitCalculator)}>
      <Accordion.Item eventKey="0">
        <Accordion.Header onClick={() => handleToggle("profitCalculator")}>
          Profit Calculator
        </Accordion.Header>
        <Accordion.Body className="analysis-padding">
          <div id="calculator">
            <table className="table profited-table  table-bordered simple-alert-table mb-0 mt-0">
              <tbody>
                <tr>
                  <td style={{ width: "50%" }} className="ranks-description">
                    Cost Price
                  </td>
                  <td>
                    <div className="form-group field-cost">
                      <div className="input-group">
                        <span
                          className="input-group-addon focus-next-input"
                          id="sas-cost-currency-symbol"
                        >
                          $
                        </span>
                        <input
                          type="text"
                          id="cost"
                          className="aj-save form-control money-input no-arrows input-cost"
                          name="costPrice"
                          value={isCostPrice}
                          defaultValue={isCostPrice}
                          step="any"
                          readOnly={isLoading}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            let { value } = e.target;
                            setIsCostPrice(value);
                          }}
                          onBlur={() => {
                            let value = String(isCostPrice).trim();
                            if (value === "") {
                              value = "0.00";
                            } else {
                              const [, decimalPart] = value.split(".");
                              if (decimalPart && decimalPart.length >= 2) {
                                value = parseFloat(value).toString();
                              } else {
                                value = parseFloat(value).toFixed(2);
                              }
                            }
                            setIsCostPrice(value);
                          }}
                        />
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td style={{ width: "50%" }} className="ranks-description">
                    Sale Price
                  </td>
                  <td>
                    <div className="form-group field-sale_price">
                      <div className="input-group">
                        <span
                          className="input-group-addon focus-next-input"
                          id="sas-sale-currency-symbol"
                        >
                          $
                        </span>
                        <input
                          type="text"
                          id="sale_price"
                          className="aj-save form-control money-input no-arrows input-cost"
                          name="salePrice"
                          step="any"
                          value={isSalePrice}
                          defaultValue={isSalePrice}
                          readOnly={isLoading}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            let { value } = e.target;

                            setIsSalePrice(value);
                          }}
                          onBlur={() => {
                            let value = String(isSalePrice).trim();
                            if (value === "") {
                              value = "0.00";
                            } else {
                              const [, decimalPart] = value.split(".");
                              if (decimalPart && decimalPart.length >= 2) {
                                value = parseFloat(value).toString();
                              } else {
                                value = parseFloat(value).toFixed(2);
                              }
                            }
                            setIsSalePrice(value);
                          }}
                        />
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td
                    style={{ width: "50%", verticalAlign: "middle" }}
                    className="ranks-description"
                  >
                    Fulfilment Type
                  </td>
                  <td className="d-flex justify-content-end align-items-center">
                    <div className="toggler toggler-three">
                      <input
                        type="checkbox"
                        className="checkbox-desc"
                        id="fulfilmentType"
                        onChange={handleCheckboxChange}
                        checked={
                          productDetails?.profitCalculator?.fulfilmentType ===
                          "FBM"
                        }
                      />
                      <label
                        htmlFor="fulfilmentType"
                        className="unchecked-label seller-description"
                      >
                        FBA
                      </label>
                      <label
                        htmlFor="fulfilmentType"
                        className="checked-label seller-description"
                      >
                        FBM
                      </label>
                      <div className="background checked-background"></div>
                      <div className="background unchecked-background"></div>
                    </div>
                  </td>
                </tr>
                {productDetails?.profitCalculator?.fulfilmentType === "FBA" ? (
                  <tr>
                    <td
                      style={{ width: "50%", verticalAlign: "middle" }}
                      className="ranks-description"
                    >
                      Storage (Months)
                    </td>
                    <td style={{ textAlign: "right" }}>
                      <Tooltip
                        title={
                          <div className="content-tooltip">
                            {productDetails?.profitCalculator?.storage_Months}
                          </div>
                        }
                        placement="bottom"
                        classes={{ tooltip: classes.tooltip }}
                      >
                        <input
                          type="range"
                          className="range "
                          name="storage_Months"
                          onChange={(e) => handleChange(e)}
                          value={
                            productDetails?.profitCalculator?.storage_Months
                          }
                          max={12}
                          step="1"
                        />
                      </Tooltip>
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td style={{ width: "50%" }} className="ranks-description">
                      FBM Cost
                    </td>
                    <td>
                      <div className="form-group field-fbm_fulfilment_cost has-success">
                        <div className="form-group field-sale_price field-ex">
                          <div className="input-group">
                            <span
                              className="input-group-addon focus-next-input"
                              id="sas-fulfilment-cost-currency-symbol"
                            >
                              $
                            </span>
                            <input
                              type="text"
                              id="FBMCost"
                              className="form-control aj-save money-input input-cost"
                              name="FBMCost"
                              pattern="[0-9.]*"
                              value={productDetails?.profitCalculator?.FBMCost}
                              onChange={(e) => handleChange(e)}
                              aria-invalid="false"
                            />
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>

            <table
              className="table table-bordered  simple-alert-table mb-0"
              // style={{ marginTop: "-4px" }}
            >
              <tbody>
                <tr>
                  <td style={{ width: "50%" }} className="profit-text">
                    Profit
                    <button
                      className="collapse-button"
                      onClick={toggleCollapse3}
                    >
                      {isCollapsed3 ? <FaAngleDown /> : <FaAngleUp />}
                    </button>
                  </td>
                  <td style={{ textAlign: "right" }}>
                    <span
                      id="saslookup-profit"
                      className={profitCalculatorProfit(
                        productDetails?.profitCalculator?.profit
                      )}
                    >
                      {productDetails?.profitCalculator?.fulfilmentType ==
                        "FBA" &&
                      productDetails.profitCalculator.totalFees
                        .fulfilment_FBA == 0 ? (
                        <Tooltip
                          title={
                            <div className="referral-fee-tooltip">
                              FBA Fee Unknown
                            </div>
                          }
                          placement="top"
                          classes={{ tooltip: classes.tooltip }}
                        >
                          <span className="dotted-border cursor-pointer">
                            <GoAlertFill size={16} color="#A94442" />
                          </span>
                        </Tooltip>
                      ) : (
                        <></>
                      )}
                      &nbsp;
                      {(() => {
                        const profit = Number(
                          productDetails?.profitCalculator?.profit.replace(
                            /,/g,
                            ""
                          )
                        );

                        const formattedCost = Math.abs(profit).toFixed(2);
                        return profit < 0
                          ? `- $ ${formattedCost}`
                          : `$ ${formattedCost}`;
                      })()}
                    </span>
                  </td>
                </tr>
                {!isCollapsed3 && (
                  <tr className="collapsed-content p-0">
                    <td colSpan={2}>
                      <ul className="detail-items d-flex flex-column gap-2">
                        <li className="d-flex justify-content-between align-items-center">
                          <span className="ranks-description">Sale Price</span>
                          <span className="enough-description">
                            $
                            {profitCalculatorSalePrice(
                              productDetails?.profitCalculator?.salePrice
                            )}
                          </span>
                        </li>
                        <li className="d-flex justify-content-between align-items-center">
                          <span className="ranks-description">Cost Price</span>
                          <span className="enough-description">
                            $&nbsp;
                            {profitCalculatorCostPrice(
                              productDetails?.profitCalculator?.costPrice
                            )}
                          </span>
                        </li>
                        <li className="d-flex justify-content-between align-items-center">
                          <span className="ranks-description">Total Fees</span>
                          <span className="enough-description">
                            $ &nbsp;
                            {calculateTotalFees(
                              productDetails?.profitCalculator?.totalFees
                            )?.toFixed(2)}
                          </span>
                        </li>
                      </ul>
                    </td>
                  </tr>
                )}
                <tr>
                  <td style={{ width: "50%" }}>
                    <span className="profit-text">ROI</span>
                  </td>
                  <td style={{ textAlign: "right" }}>
                    <span
                      id="saslookup-profit"
                      className={profitCalculatorRoiClass(
                        productDetails?.profitCalculator?.roi
                      )}
                    >
                      {(() => {
                        let roi = productDetails?.profitCalculator?.roi.replace(
                          /,/g,
                          ""
                        );
                        roi = isFinite(Number(roi)) ? Number(roi) : 0;

                        const formattedCost = Math.abs(roi).toFixed(2);
                        return roi < 0
                          ? `- ${formattedCost} %`
                          : `${formattedCost} %`;
                      })()}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td style={{ width: "50%" }} className="ranks-description">
                    <span className="profit-text">Maximum Cost</span>
                    <button
                      className="collapse-button"
                      onClick={toggleCollapse1}
                    >
                      {isCollapsed1 ? <FaAngleDown /> : <FaAngleUp />}
                    </button>
                  </td>
                  <td style={{ textAlign: "right" }}>
                    <span
                      id="saslookup-profit"
                      role="button"
                      tabIndex={0}
                      className={profitCalculatorRoiClass(
                        productDetails?.profitCalculator?.maxCost
                      )}
                      onClick={() => handleMaxCostValue()}
                      onKeyDown={(e) => {
                        if (e.key === "Enter" || e.key === " ") {
                          handleMaxCostValue();
                        }
                      }}
                    >
                      {(() => {
                        const maxCostString =
                          productDetails?.profitCalculator?.maxCost ?? "0";
                        const maxCost = Number(maxCostString.replace(/,/g, ""));

                        const formattedCost = Math.abs(maxCost).toFixed(2);
                        return maxCost < 0
                          ? `- $ ${formattedCost}`
                          : `$ ${formattedCost}`;
                      })()}
                    </span>
                  </td>
                </tr>
                {!isCollapsed1 && (
                  <tr className="collapsed-content p-0">
                    <td colSpan={2}>
                      <ul className="detail-items d-flex flex-column gap-2">
                        <li className="d-flex justify-content-between align-items-center">
                          <span className="ranks-description">Min. ROI</span>
                          <span className="enough-description">25%</span>
                        </li>
                        <li className="d-flex justify-content-between align-items-center">
                          <span className="ranks-description">Min. Profit</span>
                          <span className="enough-description">
                            $&nbsp;3.00
                          </span>
                        </li>
                      </ul>
                    </td>
                  </tr>
                )}
                <tr>
                  <td style={{ width: "50%" }} className="profit-text">
                    Total Fees
                    <button
                      className="collapse-button"
                      onClick={toggleCollapse2}
                    >
                      {isCollapsed2 ? <FaAngleDown /> : <FaAngleUp />}
                    </button>
                  </td>
                  <td style={{ textAlign: "right" }}>
                    <span id="saslookup-profit" className="enough-description">
                      $&nbsp;
                      {calculateTotalFees(
                        productDetails?.profitCalculator?.totalFees
                      )?.toFixed(2)}
                    </span>
                  </td>
                </tr>
                {!isCollapsed2 && (
                  <tr className="collapsed-content p-0">
                    <td colSpan={2}>
                      <ul
                        className="detail-items d-flex flex-column gap-2"
                        style={{ width: "100%" }}
                      >
                        <li className="d-flex justify-content-between align-items-center">
                          <span className="ranks-description">
                            <Tooltip
                              title={
                                <div className="referral-fee-tooltip">
                                  {profitCalculatorReferralFeeTooltips(
                                    referralFee
                                  )}{" "}
                                  %
                                </div>
                              }
                              placement="top"
                              classes={{ tooltip: classes.tooltip }}
                            >
                              <span className="ranks-description dotted-border cursor-pointer">
                                Referral Fee
                              </span>
                            </Tooltip>
                          </span>
                          <span className="enough-description">
                            $&nbsp;
                            {profitCalculatorReferralFeeValue(
                              productDetails?.profitCalculator?.totalFees
                                ?.referralFee
                            )}
                          </span>
                        </li>
                        <li className="d-flex justify-content-between align-items-center">
                          <span className="ranks-description">
                            Fulfilment&nbsp;
                            {profitCalculatorFulfilmentTypeValue(
                              productDetails?.profitCalculator?.fulfilmentType
                            )}
                            &nbsp;
                            {productDetails?.profitCalculator?.fulfilmentType ==
                              "FBA" &&
                            productDetails.profitCalculator.totalFees
                              .fulfilment_FBA == 0 ? (
                              <Tooltip
                                title={
                                  <div className="referral-fee-tooltip">
                                    FBA Fee Unknown
                                  </div>
                                }
                                placement="top"
                                classes={{ tooltip: classes.tooltip }}
                              >
                                <span className="dotted-border cursor-pointer">
                                  <GoAlertFill size={16} color="#A94442" />
                                </span>
                              </Tooltip>
                            ) : (
                              <></>
                            )}
                          </span>
                          <span className="enough-description">
                            $&nbsp;
                            {profitCalculatorFulfilmentFBMTypeValue(
                              productDetails?.profitCalculator?.fulfilmentType,
                              productDetails?.profitCalculator?.totalFees
                                ?.fulfilment_FBM,
                              productDetails?.profitCalculator?.totalFees
                                ?.fulfilment_FBA
                            )}
                          </span>
                        </li>
                        <li className="d-flex justify-content-between align-items-center">
                          <span className="ranks-description">Closing Fee</span>
                          <span className="enough-description">
                            $&nbsp;
                            {profitCalculatorClosingFee(
                              productDetails?.profitCalculator?.totalFees
                                ?.closingFee
                            )}
                          </span>
                        </li>
                        <li className="d-flex justify-content-between align-items-center">
                          <span
                            className={`${profitCalculatorFulfilmentTypeClass(
                              productDetails?.profitCalculator?.fulfilmentType
                            )}`}
                          >
                            Storage Fee
                          </span>
                          <span className="enough-description">
                            $&nbsp;
                            {profitCalculatorStorageFeeValue(
                              productDetails?.profitCalculator?.totalFees
                                ?.storageFee
                            )}
                          </span>
                        </li>
                        <li className="d-flex justify-content-between align-items-center">
                          <span
                            className={`${profitCalculatorFulfilmentTypeClass(
                              productDetails?.profitCalculator?.fulfilmentType
                            )}`}
                          >
                            Prep Fee
                          </span>
                          <span className="enough-description">
                            $&nbsp;
                            {profitCalculatorStorageFeeValue(
                              productDetails?.profitCalculator?.totalFees
                                ?.prepFee
                            )}
                          </span>
                        </li>
                        <li className="d-flex justify-content-between align-items-center">
                          <span
                            className={`${profitCalculatorFulfilmentTypeClass(
                              productDetails?.profitCalculator?.fulfilmentType
                            )}`}
                          >
                            Inbound Shipping
                          </span>
                          <span className="enough-description">
                            $&nbsp;
                            {profitCalculatorStorageFeeValue(
                              productDetails?.profitCalculator?.totalFees
                                ?.inboundShipping
                            )}
                          </span>
                        </li>
                        <li className="d-flex justify-content-between align-items-center">
                          <span>
                            <Tooltip
                              title={
                                <div className="referral-fee-tooltip">
                                  Estimated maximum Inbound Placement Service
                                  fee for Amazon Optimized Splits. Storage Fee
                                  <br />
                                  <br />
                                  Click to cycle through the different Inbound
                                  Placement services.
                                </div>
                              }
                              placement="top"
                              classes={{ tooltip: classes.tooltip }}
                            >
                              <span
                                className={`${profitCalculatorFulfilmentTypeClass(
                                  productDetails?.profitCalculator
                                    ?.fulfilmentType
                                )}`}
                              >
                                Inbound Placement (O)
                              </span>
                            </Tooltip>
                          </span>
                          <span className="enough-description">
                            $&nbsp;
                            {profitCalculatorStorageFeeValue(
                              productDetails?.profitCalculator?.totalFees
                                ?.inboundPlacement_O
                            )}
                          </span>
                        </li>
                        <li className="d-flex justify-content-between align-items-center">
                          <span className="ranks-description">Misc Fee</span>
                          <span className="enough-description">
                            $&nbsp;
                            {profitCalculatorStorageFeeValue(
                              productDetails?.profitCalculator?.totalFees
                                ?.miscFee
                            )}
                          </span>
                        </li>
                        <li className="d-flex justify-content-between align-items-center">
                          <span className="ranks-description">
                            Misc Fee (% of cost)
                          </span>
                          <span className="enough-description">
                            $&nbsp;
                            {profitCalculatorStorageFeeValue(
                              productDetails?.profitCalculator?.totalFees
                                ?.miscFee_PercentageOfCost
                            )}
                          </span>
                        </li>
                      </ul>
                    </td>
                  </tr>
                )}
                <tr>
                  <td style={{ width: "50%" }} className="ranks-description">
                    Discount
                  </td>
                  <td style={{ textAlign: "right" }}>
                    <span id="saslookup-profit" className="enough-description">
                      $&nbsp;
                      {profitCalculatorDiscount(
                        totalDiscount,
                        productDetails?.profitCalculator?.costPrice,
                        productDetails?.profitCalculator?.discount
                      )}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td style={{ width: "50%" }} className="ranks-description">
                    <span className="ranks-description">Profit Margin</span>
                  </td>
                  <td style={{ textAlign: "right" }}>
                    <span id="saslookup-profit" className="enough-description">
                      {(() => {
                        let profitMargin =
                          productDetails?.profitCalculator?.profitMargin.replace(
                            /,/g,
                            ""
                          );
                        profitMargin = isFinite(Number(profitMargin))
                          ? Number(profitMargin)
                          : 0;

                        const formattedCost = Math.abs(profitMargin).toFixed(2);
                        return profitMargin < 0
                          ? `- ${formattedCost} %`
                          : `${formattedCost} %`;
                      })()}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td style={{ width: "50%" }} className="ranks-description">
                    <span className="ranks-description">
                      Breakeven Sale Price
                    </span>
                  </td>
                  <td style={{ textAlign: "right" }}>
                    <span id="saslookup-profit" className="enough-description">
                      {(() => {
                        const breakevenSalePrice = Number(
                          productDetails?.profitCalculator?.breakevenSalePrice.replace(
                            /,/g,
                            ""
                          )
                        );

                        const formattedCost =
                          Math.abs(breakevenSalePrice).toFixed(2);
                        return breakevenSalePrice < 0
                          ? `- $ ${formattedCost}`
                          : `$ ${formattedCost}`;
                      })()}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td style={{ width: "50%" }} className="ranks-description">
                    <span className="ranks-description">
                      Estimated Amz. Payout
                    </span>
                    <button
                      className="collapse-button"
                      onClick={toggleCollapse4}
                    >
                      {isCollapsed4 ? (
                        <FaAngleDown className="total-text" />
                      ) : (
                        <FaAngleUp className="total-text" />
                      )}
                    </button>
                  </td>
                  <td style={{ textAlign: "right" }}>
                    <span id="saslookup-profit" className="enough-description">
                      {(() => {
                        const payout = Number(estimates?.payout);

                        const formattedCost = Math.abs(payout).toFixed(2);
                        return payout < 0
                          ? `- $ ${formattedCost}`
                          : `$ ${formattedCost}`;
                      })()}
                    </span>
                  </td>
                </tr>
                {!isCollapsed4 && (
                  <tr className="collapsed-content p-0">
                    <td colSpan={2}>
                      <ul className="detail-items d-flex flex-column gap-2">
                        <li className="d-flex justify-content-between align-items-center">
                          <span className="ranks-description">Sale Price</span>
                          <span className="enough-description">
                            $&nbsp;
                            {estimates.salePrice?.toFixed(2)}
                          </span>
                        </li>
                        <li className="d-flex justify-content-between align-items-center">
                          <span className="ranks-description">
                            Referral Fee
                          </span>
                          <span className="enough-description">
                            - $&nbsp;
                            {estimates.referralFee?.toFixed(2)}
                          </span>
                        </li>
                        <li className="d-flex justify-content-between align-items-center">
                          <span className="ranks-description">
                            Fulfilment Fee
                          </span>
                          <span className="enough-description">
                            - $&nbsp;
                            {estimates?.fulfilmentFee?.toFixed(2)}
                          </span>
                        </li>
                        <li className="d-flex justify-content-between align-items-center">
                          <span className="ranks-description">Closing Fee</span>
                          <span className="enough-description">
                            $&nbsp;
                            {estimates?.closingFee?.toFixed(2)}
                          </span>
                        </li>
                      </ul>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>

            <table
              className="table table-custom  table-bordered simple-alert-table mb-0 "
              // style={{ marginTop: "-4px" }}
            >
              <tbody>
                <tr>
                  <td style={{ width: "50%" }} className="ranks-description">
                    <Tooltip
                      title={
                        <div>
                          Enter quantity to see quick summary calculations and
                          for export to Google Sheets or STK
                        </div>
                      }
                      placement="top"
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <div
                        className=""
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        rel="noopener noreferrer"
                      >
                        <span className="ranks-description dotted-border cursor-pointer">
                          Quantity
                        </span>
                      </div>
                    </Tooltip>
                  </td>
                  <td>
                    <div className="input-group ">
                      <input
                        type="text"
                        id="quantity"
                        className="aj-save form-control number-input"
                        pattern="[0-9]*"
                        // inputmode="number"
                        step="any"
                        name="quantity"
                        value={productDetails?.profitCalculator?.quantity}
                        onChange={(e) => handleChange(e)}
                        style={{ textAlign: "right" }}
                      />
                    </div>
                  </td>
                </tr>
                {productDetails?.profitCalculator?.quantity !== 1 ? (
                  <>
                    <tr>
                      <td
                        style={{ width: "50%" }}
                        className="ranks-description "
                      >
                        <span className="ranks-description">Cost</span>
                      </td>
                      <td style={{ textAlign: "right" }}>
                        <span className="enough-description">
                          $&nbsp;
                          {profitCalculatorCost(
                            productDetails?.profitCalculator?.costPrice,
                            productDetails?.profitCalculator?.quantity
                          )}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{ width: "50%" }}
                        className="ranks-description"
                      >
                        <span className="ranks-description">Sale</span>
                      </td>
                      <td style={{ textAlign: "right" }}>
                        <span className="enough-description">
                          $&nbsp;
                          {profitCalculatorCost(
                            productDetails?.profitCalculator?.salePrice,
                            productDetails?.profitCalculator?.quantity
                          )}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{ width: "50%" }}
                        className="ranks-description"
                      >
                        <span className="ranks-description">Total Profit</span>
                      </td>
                      <td style={{ textAlign: "right" }}>
                        <span className="enough-description">
                          $&nbsp;
                          {profitCalculatorCost(
                            productDetails?.profitCalculator?.profit,
                            productDetails?.profitCalculator?.quantity
                          )}
                        </span>
                      </td>
                    </tr>
                  </>
                ) : (
                  <></>
                )}
              </tbody>
            </table>
          </div>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default ProfitCalculator;
