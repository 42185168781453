import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { DataGrid, GridRowId } from "@mui/x-data-grid";
import { GridRowSelectionModel } from "@mui/x-data-grid";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { IoIosStar } from "react-icons/io";
import { HiDownload } from "react-icons/hi";
import Box from "@mui/material/Box";
import { message } from "antd";
import toast from "react-hot-toast";
import "./index.css";
import {
  addFavListProduct,
  getFavListSource,
  getGraphDetails,
  postFavListSource,
  productDetails,
  token,
  FavListIsExists,
  shippingSettings,
  scanSettings,
  scanSettingsUpdate,
  shippingSettingsUpdate,
} from "../../Service/services";
import {
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import { FaAmazon } from "react-icons/fa";
import { MdOutlineBarChart } from "react-icons/md";
import { FaRegImage } from "react-icons/fa6";
import { Button, Input, Modal, Popconfirm } from "antd";
import Loader from "../Loader";
import { IoMdAdd } from "react-icons/io";
import CopyText from "../CopyText";
import { SearchContext } from "../../context/searchContext";
import Spinner from "react-bootstrap/Spinner";
import axios from "axios";
import OverviewModal from "./overviewModal";
import ScanSettingModal from "./scanSettingModal";
import ImageTooltip from "./imageTooltip";
import { IoCalculatorSharp } from "react-icons/io5";
import { MdAssignmentLate } from "react-icons/md";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { max } from "moment";
import ScanNewModal from "./scanNewModal";
import KeepaChartTooltip from "./keepaChartTooltip";

const Token = localStorage.getItem("accessToken");
interface RowData {
  id: number;
  from: string;
  to: string;
  price: string;
}
const ParticularItem: React.FunctionComponent = () => {
  const [islist, setIsList] = useState<any>([]);
  const [isExport, setIsExport] = useState<any>();
  const [isresponse, setIsResponse] = useState<any>([]);
  const [isInput, setIsInput] = useState<any>();
  const [isData, setIsDataId] = useState<any>([]);
  const [response, setresponse] = useState<any>([]);
  const [isRow, setIsRow] = useState<any>([]);
  const [isgraph, setIsGraph] = useState<any>(null);
  const [isCurrentPage, setIsCurrentPage] = useState<any>(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isColumns, setIsColumns] = useState<any>([]);
  const [isFavList, setIsFavList] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isOkButtonDisabled, setIsOkButtonDisabled] = useState<boolean>(true);
  const [isgraphLoading, setIsGraphLoading] = useState<boolean>(true);
  const [isCompressChecked, setIsCompressChecked] = useState<boolean>(false);
  const [isExportLoading, setIsExportLoading] = useState(false);
  const [show, setShow] = useState(false);
  const userParams = useParams();
  const [selectedOption, setSelectedOption] = useState("FBA");
  const [isShowModal, setIsShowModal] = useState<boolean>(false);
  const [isShowNewModal, setIsShowNewModal] = useState<boolean>(false);
  const [hoveredImage, setHoveredImage] = useState<any>(null);
  const [keepaImageCheck, setKeepaImageCheck] = useState<boolean>(false);
  const userData = JSON.parse(localStorage.getItem("userProfile") || "{}");
  const [isPrepFee, setIsPrepFee] = useState<any>(0);
  const [isAmazonCheck, setIsAmazonCheck] = useState<any>("");
  const [isKeepaChartDataRange, setIsKeepaChartDataRange] = useState<any>("");
  const [rows, setRows] = useState<RowData[]>([
    { id: Date.now(), from: "", to: "", price: "" },
  ]);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState<boolean>(false);
  const [shppingCostValue, setShippingCostValue] = useState<any>("");
  const [scanSettingLoading, setScanSettingLoading] = useState<boolean>(false);
  const [shippingSettingsLoading, setShippingSettingsLoading] =
    useState<boolean>(false);
  const [shippingSettingsError, setShippingSettingsError] =
    useState<boolean>(false);
  const [shippingRoleError, setShippingRoleError] = useState<boolean>(false);
  const handleChange = (event: any) => {
    setSelectedOption(event.target.value);
  };
  const { setSearchValue, setIsGobalFilter, searchValue } =
    useContext(SearchContext) ??
    (() => {
      throw new Error(
        "SearchContext is undefined. Ensure the component is within SearchState."
      );
    })();

  const handleTitleClick = (params: any) => {
    setSearchValue(params.row.asin);
    setShow(true);
  };

  useEffect(() => {
    token();
  }, []);
  useEffect(() => {
    getAll();
  }, [isCurrentPage, selectedOption]);

  const getAll = async () => {
    setIsLoading(true);
    let IsFavoriteFile;
    if (userParams.code === "Favorite") {
      IsFavoriteFile = true;
    } else {
      IsFavoriteFile = false;
    }
    let request = { page: isCurrentPage, perPage: 50 };
    try {
      const rulesResponse = await shippingSettings();
      const responseInArray = rulesResponse.data?.shippingRules.map(
        (value: any, index: any) => {
          return {
            id: index,
            from: value.from,
            to: value.to,
            price: value.cost,
          };
        }
      );
      setIsCheckboxChecked(rulesResponse.data?.isShippingRulesEnabled);
      setShippingCostValue(rulesResponse.data?.shippingCostPerPound);
      setRows(
        responseInArray?.length
          ? responseInArray
          : [{ id: Date.now(), from: "", to: "", price: "" }]
      );
      const scanSettingResponse = await scanSettings();
      if (scanSettingResponse) {
        setIsAmazonCheck(scanSettingResponse.data?.amazonCheck);
        setIsKeepaChartDataRange(scanSettingResponse.data?.keepaChartDateRange);
        setIsPrepFee(scanSettingResponse.data?.prepFeePerItem);
      }
      const object = {
        productId: userParams.id,
        request: request,
        IsFavoriteFile:IsFavoriteFile,
        selectedOption:selectedOption,
        isShippingRulesEnabled:rulesResponse?.data?.isShippingRulesEnabled,
        amazonCheck: scanSettingResponse.data?.amazonCheck,
        keepaChartDateRange: scanSettingResponse.data?.keepaChartDateRange,
        shippingCostPerPound: rulesResponse.data?.shippingCostPerPound
      }
      const response = await productDetails(object );
      setIsResponse(response.data.data);
      setresponse(response.data);
    } catch (err) {
    } finally {
      setIsLoading(false);
    }
  };
  const handleChartIconHover = (value: any) => {
    setIsGraph(null);
    setIsGraphLoading(true);
    setKeepaImageCheck(true);
    const request = {
      country: value.row.countryCode,
      asin: value.row.asin,
      range: isKeepaChartDataRange,
    };
    getGraph(request);
  };
  const getGraph = async (request: any) => {
    try {
      setIsGraph(null);
      setIsGraphLoading(true);
      const response = await getGraphDetails(request);
      const imageBuffer = await response.data;
      const base64Image = btoa(
        new Uint8Array(imageBuffer).reduce(
          (data, byte) => data + String.fromCharCode(byte),
          ""
        )
      );
      const imageUrl = `data:image/png;base64,${base64Image}`;
      setIsGraph(imageUrl);
    } catch (err) {
      console.error("Error fetching graph", err);
    } finally {
      setIsGraphLoading(false);
    }
  };
  const handlePageChange = (event: any, value: any) => {
    setIsCurrentPage(value);
  };
  const getRowClassName = (params: any) => {
    return params.row.id % 2 === 0 ? "even-row" : "odd-row";
  };

  const handleMouseEnter = (imageUrl: any) => {
    setHoveredImage(imageUrl);
  };

  const handleMouseLeave = () => {
    setIsGraph(null);
    setHoveredImage(null);
    setKeepaImageCheck(false);
  };
  const powerUserCheck = (userData: any, userParams: any, paramsValue: any) => {
    if (userData && !userData.isPowerUser) {
      return (
        <Tooltip
          placement="top"
          title="Upgrade to a paid plan to unlock Power Up feature"
          classes={{ tooltip: "custom-tooltip-item" }}
        >
          <IconButton>
            <IoIosInformationCircleOutline
              className="info-icon"
              size={25}
              data-tooltip-id="my-tooltip-2"
            />
          </IconButton>
        </Tooltip>
      );
    } else if (
      userData?.isPowerUser &&
      userParams?.enablePowerUpData === "false"
    ) {
      return (
        <Tooltip
          placement="top"
          title="To show this data, enable PowerUp data features on Add Scan Screen."
          classes={{ tooltip: "custom-tooltip-item" }}
        >
          <IconButton>
            <IoIosInformationCircleOutline
              className="info-icon"
              size={25}
              data-tooltip-id="my-tooltip-2"
            />
          </IconButton>
        </Tooltip>
      );
    } else if (paramsValue) {
      return <CopyText value={`$${paramsValue}`} show={true} />;
    } else {
      return "-";
    }
  };
  useEffect(() => {
    if (!isresponse || isresponse.length === 0) {
      setIsColumns([]);
      setIsRow([]);
    } else {
      const columns = [
        {
          field: "imageURL",
          headerName: "",
          width: 140,
          align: "center",
          renderCell: (params: any) => (
            <>
              {params.row.haveInFavorite ? (
                <IconButton
                  style={{
                    fontSize: "small",
                    cursor:
                      params.row.asin === "Not found on Amazon"
                        ? "not-allowed"
                        : "pointer",
                  }}
                >
                  <IoIosStar className="star-colour" />
                </IconButton>
              ) : (
                <IconButton
                  style={{
                    fontSize: "small",
                    cursor:
                      params.row.asin === "Not found on Amazon"
                        ? "not-allowed"
                        : "pointer",
                  }}
                >
                  <IoIosStar />
                </IconButton>
              )}
              {params.row.asin === "Not found on Amazon" ? (
                <IconButton
                  className="img-pro-duct"
                  style={{
                    fontSize: "small",
                    cursor:
                      params.row.asin === "Not found on Amazon"
                        ? "not-allowed"
                        : "pointer",
                  }}
                >
                  <FaRegImage />
                </IconButton>
              ) : (
                <IconButton style={{ fontSize: "small" }}>
                  <FaRegImage
                    onMouseEnter={() => handleMouseEnter(params.row.imageURL)}
                    onMouseLeave={handleMouseLeave}
                    style={{ display: "inline-block" }}
                  />
                </IconButton>
              )}

              <IconButton
                style={{
                  fontSize: "small",
                  cursor:
                    params.row.asin === "Not found on Amazon"
                      ? "not-allowed"
                      : "pointer",
                }}
                onClick={() =>
                  window.open(
                    `https://www.amazon.com/dp/${params.row.asin}/ref=olp-opf-redir?aod=1&ie=UTF8&condition=new`,
                    "_blank"
                  )
                }
              >
                <FaAmazon className="amazon-image" />
              </IconButton>
              {params.row.asin === "Not found on Amazon" ? (
                <IconButton
                  style={{
                    fontSize: "small",
                    cursor:
                      params.row.asin === "Not found on Amazon"
                        ? "not-allowed"
                        : "pointer",
                  }}
                >
                  <MdOutlineBarChart data-tooltip-id="my-tooltip-2" />
                </IconButton>
              ) : (
                <IconButton
                  style={{ fontSize: "small" }}
                  onMouseEnter={() => handleChartIconHover(params)}
                  onMouseLeave={handleMouseLeave}
                >
                  <MdOutlineBarChart style={{ display: "inline-block" }} />
                </IconButton>
              )}
            </>
          ),
        },
        {
          field: "productSearch",
          headerName: "Search Identifier",
          width: 140,
          align: "left",
          renderCell: (params: any) => (
            <div>
              <CopyText value={params.row.productSearch} show={true} />
            </div>
          ),
        },
        {
          field: "asin",
          headerName: "ASIN",
          width: 140,
          align: "left",
          renderCell: (params: any) => (
            <div>
              <CopyText value={params.row.asin} show={true} />
            </div>
          ),
        },
        {
          field: "upc",
          headerName: "UPC",
          width: 140,
          renderCell: (params: any) => (
            <div className="centered-cell">
              {params.value && (
                <>
                  <CopyText value={params.value} show={true} />
                </>
              )}
            </div>
          ),
        },
        {
          field: "ean",
          headerName: "EAN",
          width: 190,
          renderCell: (params: any) => (
            <div className="centered-cell">
              {params.value && (
                <>
                  <CopyText value={params.value} show={true} />
                </>
              )}
            </div>
          ),
        },
        {
          field: "isbn",
          headerName: "ISBN",
          width: 130,
          renderCell: (params: any) => (
            <div className="centered-cell">
              {params.value ? (
                <CopyText value={`${params.value}`} show={true} />
              ) : (
                "-"
              )}
            </div>
          ),
        },
        {
          field: "title",
          headerName: "Title",
          width: 1200,
          renderCell: (params: any) => (
            <Tooltip title={params.row.title} arrow>
              <div
                className="hyperlink-style"
                role="link"
                tabIndex={0}
                onClick={() => handleTitleClick(params)}
                onKeyDown={(e) => {
                  if (e.key === "Enter" || e.key === " ") {
                    handleTitleClick(params);
                  }
                }}
                style={{ cursor: "pointer" }}
              >
                <CopyText
                  value={
                    params.row.title.length > 157
                      ? `${params.row.title.substring(0, 157)}...`
                      : params.row.title
                  }
                  show={true}
                />
              </div>
            </Tooltip>
          ),
        },
        {
          field: "estimatedSales",
          headerName: "Est Sales",
          width: 90,
          renderCell: (params: any) => (
            <div className="centered-cell">
              {params.value !== null && params.value <= 5 ? (
                <>
                  <span className="price-drop-icon">{"<"}</span>
                  <CopyText value={params.value} show={true} />
                </>
              ) : (
                <CopyText value={params.value} show={true} />
              )}
            </div>
          ),
        },
        {
          field: "profit",
          headerName: "Profit",
          width: 110,
          wordWrap: "break-word",
          renderCell: (params: any) => (
            <div className="centered-cell">
              <CopyText
                value={
                  params.value ? (
                    <>
                      <IoCalculatorSharp className="calculator-icon" />
                      {`$ ${params.value.toFixed(2)}`}
                    </>
                  ) : (
                    <>
                      <IoCalculatorSharp className="calculator-icon" />$ 0.00
                    </>
                  )
                }
                show={true}
              />
            </div>
          ),
        },
        {
          field: "roi",
          headerName: "ROI",
          width: 90,
          renderCell: (params: any) => (
            <div className="centered-cell">
              <CopyText
                value={params.value ? `${params.value}` : "0.00 %"}
                show={true}
              />
            </div>
          ),
        },
        {
          field: "margin",
          headerName: "Margin",
          width: 120,
          renderCell: (params: any) => (
            <div className="centered-cell">
              <CopyText
                value={params.value ? `${params.value}` : "0.00 %"}
                show={true}
              />
            </div>
          ),
        },
        {
          field: "salesRank",
          headerName: "Sales Rank",
          width: 110,
          renderCell: (params: any) => (
            <CopyText value={params.value} show={true} />
          ),
        },
        {
          field: "productGroup",
          headerName: "Product Group",
          width: 150,
          renderCell: (params: any) => (
            <Tooltip title={params.row.title} arrow>
              <div style={{ cursor: "pointer" }}>
                <CopyText
                  value={
                    params.row.productGroup.length > 15
                      ? `${params.row.productGroup.substring(0, 15)}...`
                      : params.row.productGroup
                  }
                  show
                />
              </div>
            </Tooltip>
          ),
        },
        {
          field: "cost",
          headerName: "Cost",
          width: 90,
          renderCell: (params: any) => (
            <div className="centered-cell">
              <CopyText
                value={params.value ? `$${params.value}` : "$0.00"}
                show={true}
              />
            </div>
          ),
        },
        {
          field: "costSubTotal",
          headerName: "Cost Sub Total",
          width: 120,
          renderCell: (params: any) => (
            <div className="centered-cell">
              <CopyText
                value={params.value ? `$${params.value}` : "$0.00"}
                show={true}
              />
            </div>
          ),
        },
        {
          field: "marginImpact",
          headerName: "Margin Impact",
          width: 120,
          renderCell: (params: any) => (
            <div className="centered-cell  param-value  ">
              <CopyText
                value={params.value ? `$${params.value}` : "$0.00"}
                show={true}
              />
            </div>
          ),
        },
        {
          field: "price",
          headerName: "Price",
          width: 90,
          renderCell: (params: any) => (
            <div className="centered-cell">
              {params.value ? (
                <CopyText value={`$${params.value}`} show={true} />
              ) : (
                <Tooltip
                  placement="right"
                  title="Price Unavailable. Product not currently in stock or has multiple variations with varying prices "
                  classes={{ tooltip: "custom-tooltip" }}
                >
                  <IconButton>
                    <MdAssignmentLate
                      className="assign-icon"
                      data-tooltip-id="my-tooltip-2"
                    />
                  </IconButton>
                </Tooltip>
              )}
            </div>
          ),
        },
        {
          field: "model",
          headerName: "Model",
          width: 120,
          renderCell: (params: any) => (
            <CopyText value={params.value} show={true} />
          ),
        },
        {
          field: "partNumber",
          headerName: "Part Number",
          width: 120,
          renderCell: (params: any) => (
            <CopyText value={params.value} show={true} />
          ),
        },
        {
          field: "manufacturer",
          headerName: "Manufacturer",
          width: 130,
          renderCell: (params: any) => (
            <CopyText value={params.value} show={true} />
          ),
        },
        {
          field: "vat",
          headerName: "Vat",
          width: 90,
          renderCell: (params: any) => (
            <div className="centered-cell">
              {params.value ? params.value : "-"}
            </div>
          ),
        },
        {
          field: "vatPercentage",
          headerName: "Vat Percentage",
          width: 140,
          renderCell: (params: any) => (
            <div className="centered-cell">
              {params.value ? params.value : "-"}
            </div>
          ),
        },
        {
          field: "vatAmount",
          headerName: "Vat Amount",
          width: 120,
          renderCell: (params: any) => (
            <div className="centered-cell">
              <CopyText
                value={
                  params.value && params.value !== "" ? params.value : "0.00"
                }
                show={true}
              />
            </div>
          ),
        },
        {
          field: "packageQuantity",
          headerName: "Package Quantity",
          width: 150,
          renderCell: (params: any) => (
            <div className="centered-cell">
              <CopyText value={params.value ? params.value : "-"} show={true} />
            </div>
          ),
        },
        {
          field: "itemHeight",
          headerName: "Item Height",
          width: 100,
          renderCell: (params: any) => (
            <div className="centered-cell">
              <CopyText
                value={
                  params.value !== undefined &&
                  params.value !== null &&
                  !isNaN(Number(params.value))
                    ? Number(params.value).toFixed(2)
                    : "-"
                }
                show={true}
              />
            </div>
          ),
        },
        {
          field: "itemLength",
          headerName: "Item Length",
          width: 100,
          renderCell: (params: any) => (
            <div className="centered-cell">
              <CopyText
                value={
                  params.value !== undefined &&
                  params.value !== null &&
                  !isNaN(Number(params.value))
                    ? Number(params.value).toFixed(2)
                    : "-"
                }
                show={true}
              />
            </div>
          ),
        },
        {
          field: "itemWidth",
          headerName: "Item Width",
          width: 100,
          renderCell: (params: any) => (
            <div className="centered-cell">
              <CopyText
                value={
                  params.value !== undefined &&
                  params.value !== null &&
                  !isNaN(Number(params.value))
                    ? Number(params.value).toFixed(2)
                    : "-"
                }
                show={true}
              />
            </div>
          ),
        },
        {
          field: "itemWeight",
          headerName: "Item Weight",
          renderCell: (params: any) => (
            <div className="centered-cell">
              <CopyText
                value={
                  params.value !== undefined &&
                  params.value !== null &&
                  !isNaN(Number(params.value))
                    ? Number(params.value).toFixed(2)
                    : "-"
                }
                show={true}
              />
            </div>
          ),
          width: 100,
        },
        {
          field: "packageDim",
          headerName: "Package Dimension",
          renderCell: (params: any) => (
            <div className="centered-cell">
              <CopyText value={params.value ? params.value : "-"} show={true} />
            </div>
          ),
          width: 170,
        },
        {
          field: "buyBoxLanded",
          headerName: "BuyBox Price",
          renderCell: (params: any) => (
            <div className="centered-cell">
              {params.value ? (
                <CopyText value={`$${params.value}`} show={true} />
              ) : (
                "-"
              )}
            </div>
          ),
          width: 130,
        },
        {
          field: "buyBoxContention",
          headerName: "BuyBox Contention",
          renderCell: (params: any) => (
            <div className="centered-cell">
              {params.value ? (
                <CopyText value={`$${params.value}`} show={true} />
              ) : (
                "-"
              )}
            </div>
          ),
          width: 150,
        },
        {
          field: "averageBuyBoxPrice30",
          headerName: "Avg BuyBoxPrice 30d",
          width: 180,
          renderCell: (params: any) => (
            <div className="centered-cell">
              {powerUserCheck(userData, userParams, params.value)}
            </div>
          ),
        },
        {
          field: "averageBuyBoxPrice90",
          headerName: "Avg BuyBoxPrice 90d",
          width: 180,
          renderCell: (params: any) => (
            <div className="centered-cell">
              {powerUserCheck(userData, userParams, params.value)}
            </div>
          ),
        },
        {
          field: "averageBuyBoxPrice180",
          headerName: "Avg BuyBoxPrice 180d",
          width: 190,
          renderCell: (params: any) => (
            <div className="centered-cell">
              {powerUserCheck(userData, userParams, params.value)}
            </div>
          ),
        },
        {
          field: "rating",
          headerName: "Rating",
          width: 90,
          renderCell: (params: any) => (
            <div className="centered-cell">
              {powerUserCheck(userData, userParams, params.value)}
            </div>
          ),
        },
        {
          field: "reviews",
          headerName: "Reviews",
          width: 90,
          renderCell: (params: any) => (
            <div className="centered-cell">
              {powerUserCheck(userData, userParams, params.value)}
            </div>
          ),
        },
        {
          field: "variationParent",
          headerName: "Variation Parent",
          width: 150,
          renderCell: (params: any) => (
            <div className="centered-cell">
              {params.value ? (
                <CopyText value={`$${params.value}`} show={true} />
              ) : (
                "-"
              )}
            </div>
          ),
        },
        {
          field: "parentASIN",
          headerName: "Parent ASIN",
          width: 150,
          renderCell: (params: any) => (
            <div className="centered-cell">
              {params.value ? (
                <CopyText value={`$${params.value}`} show={true} />
              ) : (
                "-"
              )}
            </div>
          ),
        },
        {
          field: "variationCount",
          headerName: "Variation Count",
          width: 150,
          renderCell: (params: any) => (
            <div className="centered-cell">
              {params.value ? (
                <CopyText value={`$${params.value}`} show={true} />
              ) : (
                "-"
              )}
            </div>
          ),
        },
        {
          field: "offers",
          headerName: "Offers",
          width: 90,
          renderCell: (params: any) => (
            <div className="centered-cell">
              {params.value ? (
                <CopyText value={`$${params.value}`} show={true} />
              ) : (
                "-"
              )}
            </div>
          ),
        },
        {
          field: "shippingCost",
          headerName: "Shipping Cost",
          width: 130,
          renderCell: (params: any) => (
            <div className="centered-cell">
              {params.value ? (
                <CopyText value={`$${params.value}`} show={true} />
              ) : (
                "-"
              )}
            </div>
          ),
        },
        {
          field: "color",
          headerName: "Color",
          width: 120,
          renderCell: (params: any) => (
            <div className="centered-cell">
              <CopyText value={params.value ? params.value : "-"} show={true} />
            </div>
          ),
        },
        {
          field: "size",
          headerName: "Size",
          width: 160,
          renderCell: (params: any) => (
            <div className="centered-cell">
              {params.value ? (
                <CopyText value={`$${params.value}`} show={true} />
              ) : (
                "-"
              )}
            </div>
          ),
        },
        {
          field: "fbaFee",
          headerName: "FBA Fee",
          width: 120,
          renderCell: (params: any) => (
            <div className="centered-cell">
              <CopyText
                value={params.value ? `$ ${params.value.toFixed(2)}` : "$ 0.00"}
                show={true}
              />
            </div>
          ),
        },
        {
          field: "referralFee",
          headerName: "Referral Fee",
          width: 120,
          renderCell: (params: any) => (
            <div className="centered-cell">
              <CopyText
                value={params.value ? `$ ${params.value.toFixed(2)}` : "$ 0.00"}
                show={true}
              />
            </div>
          ),
        },
        {
          field: "variableClosingFee",
          headerName: "Variable Closing Fee",
          width: 170,
          renderCell: (params: any) => (
            <div className="centered-cell">
              <CopyText
                value={params.value ? `$ ${params.value.toFixed(2)}` : "$ 0.00"}
                show={true}
              />
            </div>
          ),
        },
        {
          field: "amazonLastSeen",
          headerName: "Amazon Last Seen",
          width: 200,
          renderCell: (params: any) => (
            <div className="centered-cell">
              <CopyText value={params.value ? params.value : "-"} show={true} />
            </div>
          ),
        },
        {
          field: "isSoldByAmazon",
          headerName: "Sold By Amazon",
          width: 180,
          renderCell: (params: any) => (
            <div className="centered-cell">
              {powerUserCheck(userData, userParams, params.value)}
            </div>
          ),
        },
        {
          field: "salesRankDrops30",
          headerName: "Sales Rank Drops 30",
          width: 180,
          renderCell: (params: any) => (
            <div className="centered-cell">
              {powerUserCheck(userData, userParams, params.value)}
            </div>
          ),
        },
        {
          field: "salesRankDrops90",
          headerName: "Sales Rank Drops 90",
          width: 180,
          renderCell: (params: any) => (
            <div className="centered-cell">
              {powerUserCheck(userData, userParams, params.value)}
            </div>
          ),
        },
        {
          field: "salesRankDrops180",
          headerName: "Sales Rank Drops 180",
          width: 180,
          renderCell: (params: any) => (
            <div className="centered-cell">
              {powerUserCheck(userData, userParams, params.value)}
            </div>
          ),
        },
        {
          field: "salesRankDrops365",
          headerName: "Sales Rank Drops 365",
          width: 180,
          renderCell: (params: any) => (
            <div className="centered-cell">
              {powerUserCheck(userData, userParams, params.value)}
            </div>
          ),
        },
        {
          field: "averageBSR30",
          headerName: "Average BSR 30",
          width: 150,
          renderCell: (params: any) => (
            <div className="centered-cell">
              {powerUserCheck(userData, userParams, params.value)}
            </div>
          ),
        },
        {
          field: "averageBSR90",
          headerName: "Average BSR 90",
          width: 150,
          renderCell: (params: any) => (
            <div className="centered-cell">
              {powerUserCheck(userData, userParams, params.value)}
            </div>
          ),
        },
        {
          field: "averageBSR180",
          headerName: "Average BSR 180",
          width: 170,
          renderCell: (params: any) => (
            <div className="centered-cell">
              {powerUserCheck(userData, userParams, params.value)}
            </div>
          ),
        },
        {
          field: "amazonInStockRate30",
          headerName: "Amazon In Stock Rate 30",
          width: 200,
          renderCell: (params: any) => (
            <div className="centered-cell">
              {powerUserCheck(userData, userParams, params.value)}
            </div>
          ),
        },
        {
          field: "amazonInStockRate90",
          headerName: "Amazon In Stock Rate 90",
          width: 200,
          renderCell: (params: any) => (
            <div className="centered-cell">
              {powerUserCheck(userData, userParams, params.value)}
            </div>
          ),
        },
        {
          field: "amazonInStockRate180",
          headerName: "Amazon In Stock Rate 180",
          width: 200,
          renderCell: (params: any) => (
            <div className="centered-cell">
              {powerUserCheck(userData, userParams, params.value)}
            </div>
          ),
        },
        {
          field: "lowestBSR30",
          headerName: "Lowest BSR 30",
          width: 130,
          renderCell: (params: any) => (
            <div className="centered-cell">
              {powerUserCheck(userData, userParams, params.value)}
            </div>
          ),
        },
        {
          field: "lowestBSR90",
          headerName: "Lowest BSR 90",
          width: 130,
          renderCell: (params: any) => (
            <div className="centered-cell">
              {powerUserCheck(userData, userParams, params.value)}
            </div>
          ),
        },
        {
          field: "lowestBSR180",
          headerName: "Lowest BSR 180",
          width: 130,
          renderCell: (params: any) => (
            <div className="centered-cell">
              {powerUserCheck(userData, userParams, params.value)}
            </div>
          ),
        },
        {
          field: "payout",
          headerName: "Payout",
          width: 130,
          renderCell: (params: any) => (
            <div className="centered-cell">
              {params.value ? (
                <CopyText value={`$${params.value}`} show={true} />
              ) : (
                "-"
              )}
            </div>
          ),
        },
        {
          field: "packageWeighti",
          headerName: "Package Weight",
          width: 130,
          renderCell: (params: any) => (
            <div className="centered-cell">
              {params.value ? params.value : "-"}
            </div>
          ),
        },
        {
          field: "LowestFBA",
          headerName: "Lowest FBA",
          width: 130,
          renderCell: (params: any) => (
            <div className="centered-cell">
              {params.value ? params.value : "-"}
            </div>
          ),
        },
        {
          field: "lowestFBM",
          headerName: "Lowest FBM",
          width: 130,
          renderCell: (params: any) => (
            <div className="centered-cell">
              {params.value ? (
                <CopyText value={`$${params.value.toFixed(2)}`} show={true} />
              ) : (
                "-"
              )}
            </div>
          ),
        },
        {
          field: "inboundShippingEstimate",
          headerName: "Inbound Shipping Estimate",
          width: 220,
          wordWrap: "break-word",
          renderCell: (params: any) => (
            <div className="centered-cell">
              {params.value ? (
                <CopyText value={`$${params.value.toFixed(2)}`} show={true} />
              ) : (
                "-"
              )}
            </div>
          ),
        },
        {
          field: "fbaOffers",
          headerName: "New FBA Offers",
          width: 150,
          renderCell: (params: any) => (
            <div className="centered-cell">
              {params.value ? (
                <CopyText value={`$${params.value.toFixed(2)}`} show={true} />
              ) : (
                "0.00"
              )}
            </div>
          ),
        },
        {
          field: "fbmOffers",
          headerName: "New FBM Offers",
          width: 150,
          renderCell: (params: any) => (
            <div className="centered-cell">
              {params.value ? (
                <CopyText value={`$${params.value.toFixed(2)}`} show={true} />
              ) : (
                "-"
              )}
            </div>
          ),
        },
        {
          field: "isRootCategory",
          headerName: "Is Top Level Category",
          width: 160,
          renderCell: (params: any) => (
            <div className="centered-cell">
              <input
                type="checkbox"
                className="checkbox-desc"
                style={{ width: "19px", height: "19px" }}
                checked={!!params.value}
                readOnly
              />
            </div>
          ),
        },
        {
          field: "isBrandAlert",
          headerName: "Brand Alerts",
          width: 110,
          renderCell: (params: any) => (
            <div className="centered-cell">
              <input
                type="checkbox"
                className="checkbox-desc"
                style={{ width: "19px", height: "19px" }}
                checked={!!params.value}
                readOnly
              />
            </div>
          ),
        },
        {
          field: "isHazmat",
          headerName: "Is Hazmat",
          width: 110,
          renderCell: (params: any) => (
            <div className="centered-cell">
              <input
                type="checkbox"
                className="checkbox-desc"
                style={{ width: "19px", height: "19px" }}
                checked={!!params.value}
                readOnly
              />
            </div>
          ),
        },

        {
          field: "isAlcoholic",
          headerName: "Is Alcoholic",
          width: 130,
          renderCell: (params: any) => (
            <div className="centered-cell">
              <input
                type="checkbox"
                className="checkbox-desc"
                style={{ width: "19px", height: "19px" }}
                checked={!!params.value}
                readOnly
              />
            </div>
          ),
        },
        {
          field: "isAdultProduct",
          headerName: "Is Adult Product",
          width: 130,
          renderCell: (params: any) => (
            <div className="centered-cell">
              <input
                type="checkbox"
                className="checkbox-desc"
                style={{ width: "19px", height: "19px" }}
                checked={!!params.value}
                readOnly
              />
            </div>
          ),
        },
      ];
      const rows = isresponse.map((item: any, index: any) => ({
        id: index,
        ...item,
      }));
      const updateColumn = columns;
      setIsColumns(updateColumn);
      setIsRow(rows);
    }
  }, [isresponse, isgraphLoading, isgraph]);

  const handleOk = () => {
    setIsModalOpen(false);
    addFavList();
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const getFavList = async () => {
    try {
      const response = await getFavListSource(105);
      setIsFavList(response.data);
      console.log(response);
    } catch (err) {
      console.log();
    }
  };

  const favListShow = isFavList?.map((item: any) => (
    <MenuItem key={item.favoriteId} value={item.favoriteId}>
      {item.favoriteName}
    </MenuItem>
  ));
  const changeHandler = (e: any) => {
    setIsList({ ...islist, [e.target.name]: e.target.value });
    setIsOkButtonDisabled(false);
  };

  const checkIfFavListExists = async () => {
    const favoriteName = isInput;
    console.log(favoriteName);

    try {
      const response = await FavListIsExists(favoriteName);
      const doesListExist = response.data;

      if (doesListExist) {
        message.error("Name already exists. Please create a new name.");
      } else {
        setIsOkButtonDisabled(true);
        await postFavList();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const submitList = async () => {
    checkIfFavListExists();
  };
  const postFavList = async () => {
    if (isInput) {
      try {
        const response = await postFavListSource(isInput);
        if (response.status === 200) {
          getFavList();
        }
      } catch (err) {}
    }
    console.log(isInput);
  };
  const addFavList = async () => {
    const request = {
      productUploadId: userParams.id,
      productIds: isData,
      favoriteId: islist.favorite,
      sourceId: 105,
    };

    try {
      const response = await addFavListProduct(request);
      if (response?.data?.success) {
        const successMessage = response?.data?.description || "successful";
        message.success(successMessage);
      } else {
        const errorMessage = response?.data?.description || "failed";
        message.error(errorMessage);
      }
    } catch (err) {
      console.error(err);
      message.error("API call failed");
    }
  };

  const onRowsSelectionHandler = (ids: GridRowId[]) => {
    const selectedRowsData = ids.map((id: any) => {
      const selectedRow = isRow.find((row: any) => row.id === id);
      return selectedRow ? selectedRow.productId : null;
    });
    setIsDataId(selectedRowsData);
  };
  const handleOkHandler = () => {
    if (isExport) {
      addExport();
    }
  };
  const toBase64 = (str: string) => {
    return btoa(unescape(encodeURIComponent(str)));
  };
  const addExport = async () => {
    try {
      setIsExportLoading(true);
      const response = await axios.post(
        `https://testapi.sellerscout.com/ProductUpload/${userParams.id}/ExportProducts`,
        {
          page: 0,
          perPage: 10,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Token}`,
            ExportFileName: toBase64(isExport),
            CompressZip: String(isCompressChecked),
            IsFavoriteFile: "false",
          },
          responseType: "blob",
        }
      );

      // Handle the file download
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = isCompressChecked
        ? `${isExport}-data.zip`
        : `${isExport}-data.xlsx`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);

      toast.success("Export successfully");
      console.log("Export successful");
    } catch (error) {
      console.error("Export failed", error);
      toast.error("Failed to export data");
    } finally {
      setIsCompressChecked(false);
      setIsExport(null);
      setIsExportLoading(false);
    }
  };
  const formatDateTime = (dateTimeString: any) => {
    const options: Intl.DateTimeFormatOptions = {
      month: "numeric",
      day: "numeric",
      year: "2-digit",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };

    const date = new Date(dateTimeString);

    if (isNaN(date.getTime())) {
      return "Invalid Date";
    }

    return date.toLocaleDateString(undefined, options);
  };
  const settingModalSettingClose = () => {
    hetRulesSetting();
    getRulesSetting();
    const modal = document.querySelector(".modal") as HTMLElement;
    if (modal) {
      modal.style.display = "none";
    }
    setShippingSettingsError(false);
    setShippingRoleError(false);
    document.body.classList.remove("modal-open");
    setIsShowModal(false);
    setIsShowNewModal(false);
  };
  useEffect(() => {
    setSearchValue("");
    setIsGobalFilter("");
  }, []);

  const handleAddRow = () => {
    setRows([{ id: Date.now(), from: "", to: "", price: "" }, ...rows]);
  };

  const handleDeleteRow = (id: number) => {
    setRows(rows.filter((row) => row.id !== id));
  };

  const handleScanSettingChange = (
    id: number,
    field: keyof RowData,
    value: string
  ) => {
    setRows((prevRows) => {
      const otherFields = prevRows.filter((row) => row.id !== id);
      const isDuplicate = otherFields.some((row) => row[field] === value);

      if (isDuplicate) return prevRows;

      return prevRows.map((row) =>
        row.id === id ? { ...row, [field]: value } : row
      );
    });
  };

  const getRulesSetting = async () => {
    try {
      const response = await shippingSettings();
      const responseInArray = response.data?.shippingRules.map(
        (value: any, index: any) => {
          return {
            id: index,
            from: value.from,
            to: value.to,
            price: value.cost,
          };
        }
      );
      setIsCheckboxChecked(response.data?.isShippingRulesEnabled);
      setRows(
        responseInArray?.length
          ? responseInArray
          : [{ id: Date.now(), from: "", to: "", price: "" }]
      );
    } catch (e) {
      console.log("e", e);
    }
  };
  const hetRulesSetting = async () => {
    try {
      const scanSettingResponse = await scanSettings();
      if (scanSettingResponse) {
        setIsAmazonCheck(scanSettingResponse.data?.amazonCheck);
        setIsKeepaChartDataRange(scanSettingResponse.data?.keepaChartDateRange);
        setIsPrepFee(scanSettingResponse.data?.prepFeePerItem);
      }
    } catch (e) {
      console.log("e", e);
    }
  };
  const handleUpdateScan = async () => {
    try {
      setScanSettingLoading(true);
      const object = {
        defaultCondition: 0,
        defaultMarketPlace: 0,
        emailNotifications: true,
        keepaSubscription: true,
        amazonCheck: Number(isAmazonCheck),
        keepaChartDateRange: Number(isKeepaChartDataRange),
        prepFee: Number(isPrepFee),
      };
      await scanSettingsUpdate(object);
      setIsShowModal(false);
      toast.success("Scan Settings updated successfully.");
      getAll();
    } catch (e) {
      console.log("e", e);
    } finally {
      setScanSettingLoading(false);
    }
  };
  const shippingSettingUpdates = async () => {
    try {
      const responseInArray = rows
        ?.map((value) => {
          return {
            from: Number(value.from),
            to: Number(value.to),
            cost: Number(value.price),
          };
        })
        .filter((item) => item.from && item.to && item.cost);
      if (
        !isCheckboxChecked &&
        (!shppingCostValue || Number(shppingCostValue) <= 0)
      ) {
        setShippingSettingsError(true);
        return;
      }

      if (isCheckboxChecked && responseInArray.length === 0) {
        setShippingRoleError(true);
        return;
      }
      setShippingRoleError(false);
      const object = {
        shippingRules: responseInArray,
        isShippingRulesEnabled: isCheckboxChecked,
        shippingCostPerPound: Number(shppingCostValue),
      };
      setShippingSettingsLoading(true);
      await shippingSettingsUpdate(object);
      toast.success("Shipping Settings updated successfully.");
      setIsShowNewModal(false);
      getAll();
    } catch (e) {
      console.log("e", e);
    } finally {
      setShippingSettingsLoading(false);
    }
  };
  return (
    <section className="px-4 ranks-offers pb-5">
      {isLoading ? (
        <div className="loading-loader">
          <Loader />
        </div>
      ) : (
        <Container fluid>
          {isExportLoading ? (
            <div className="ranks-loader-particular">
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          ) : (
            <></>
          )}
          <Row>
            <Col>
              <div className="product-item-table">
                <div className="d-flex align-items-center justify-content-between mb-3 mt-2">
                  <div className="d-flex flex-column">
                    <h1 className="title-heading">{userParams.file}</h1>
                    <span className="title-heading-span">
                      {formatDateTime(userParams.time)}
                    </span>
                  </div>
                  <div className="d-flex gap-2 align-items-center ">
                    <div
                      style={{
                        padding: "10px",
                        display: "flex",
                        width: "140px",
                      }}
                      className="fba-radio"
                    >
                      <label style={{ display: "flex", marginRight: "10px" }}>
                        <input
                          type="radio"
                          value="FBA"
                          checked={selectedOption === "FBA"}
                          onChange={handleChange}
                          style={{ transform: "scale(1.5)" }}
                        />
                        <span style={{ marginLeft: "8px" }}>FBA</span>
                      </label>
                      <label style={{ display: "flex" }}>
                        <input
                          type="radio"
                          value="FBM"
                          checked={selectedOption === "FBM"}
                          onChange={handleChange}
                          style={{ transform: "scale(1.5)" }}
                        />
                        <span style={{ marginLeft: "8px" }}>FBM</span>
                      </label>
                    </div>
                    <Popconfirm
                      title="Enter Name"
                      description={
                        <div>
                          <Input
                            onChange={(e) => setIsExport(e.target.value)}
                          />
                          <div className="pt-2 d-flex align-items-center gap-2">
                            <input
                              type="checkbox"
                              className="checkbox-desc"
                              style={{ width: "19px", height: "19px" }}
                              onChange={(e) =>
                                setIsCompressChecked(e.target.checked)
                              }
                              checked={isCompressChecked}
                            />
                            Compress
                          </div>
                        </div>
                      }
                      onConfirm={handleOkHandler}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button type="primary" className="boxering-btn">
                        <div className="d-flex align-items-center">
                          <HiDownload className="me-2" />
                          Export
                        </div>
                      </Button>
                    </Popconfirm>
                    {/* <Button
                      type="primary"
                      onClick={showModal}
                      className="boxering-btn"
                    >
                      <div className="d-flex align-items-center">
                        <IoMdAdd className="me-2" />
                        Add to favorite
                      </div>
                    </Button> */}
                    <Button
                      type="primary"
                      onClick={() => {
                        setIsShowNewModal(true);
                      }}
                      className="boxering-btn"
                    >
                      <div className="d-flex align-items-center">
                        Rules Setting
                      </div>
                    </Button>
                    <Button
                      type="primary"
                      onClick={() => {
                        setIsShowModal(true);
                      }}
                      className="boxering-btn"
                    >
                      <div className="d-flex align-items-center">
                        Scan Settings
                      </div>
                    </Button>
                    <Modal
                      title="Add Products to Favorite"
                      className="basic-modal"
                      open={isModalOpen}
                      onOk={handleOk}
                      onCancel={handleCancel}
                      okButtonProps={{ disabled: isOkButtonDisabled }}
                    >
                      <div className="d-flex justify-content-center">
                        <FormControl
                          variant="standard"
                          sx={{ m: 1, minWidth: 300 }}
                        >
                          <InputLabel
                            id="demo-simple-select-standard-label"
                            style={{ textAlign: "left" }}
                          >
                            Select Favorites List
                          </InputLabel>
                          <Select
                            name="favorite"
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            value={islist.favorite}
                            onChange={changeHandler}
                            label="Product ID Columns"
                          >
                            {favListShow}
                          </Select>
                          <br />
                          <br />
                          <div className="d-flex align-items-baseline">
                            <TextField
                              fullWidth
                              id="standard-basic"
                              label="Add New Favorite"
                              variant="standard"
                              name="add"
                              onChange={(e) => setIsInput(e.target.value)}
                              required
                              InputLabelProps={{
                                style: { textAlign: "left" },
                              }}
                            />

                            <div>
                              <Button type="primary" onClick={submitList}>
                                Add
                              </Button>
                            </div>
                          </div>
                        </FormControl>
                      </div>
                    </Modal>
                  </div>
                </div>
                <Box sx={{ height: 520, width: "100%" }}>
                  <DataGrid
                    // className="custom-grid-table"
                    rows={isRow}
                    columns={isColumns}
                    hideFooter={true}
                    checkboxSelection
                    onRowSelectionModelChange={(e: GridRowSelectionModel) =>
                      onRowsSelectionHandler(e as GridRowId[])
                    }
                    getRowClassName={getRowClassName}
                  />
                </Box>
                <Stack spacing={2} className="mt-2 mb-5">
                  <Pagination
                    variant="outlined"
                    shape="rounded"
                    count={response.lastPage}
                    page={isCurrentPage}
                    onChange={handlePageChange}
                  />
                </Stack>
              </div>
            </Col>
          </Row>
          <OverviewModal
            show={show}
            setShow={setShow}
            setSearchValue={setSearchValue}
            searchValue={searchValue}
          />
          <ScanSettingModal
            setIsPrepFee={setIsPrepFee}
            isPrepFee={isPrepFee}
            setIsAmazonCheck={setIsAmazonCheck}
            isAmazonCheck={isAmazonCheck}
            isShowModal={isShowModal}
            isKeepaChartDataRange={isKeepaChartDataRange}
            setIsKeepaChartDataRange={setIsKeepaChartDataRange}
            settingModalSettingClose={settingModalSettingClose}
            handleUpdateScan={handleUpdateScan}
            scanSettingLoading={scanSettingLoading}
          />
          <ScanNewModal
            shppingCostValue={shppingCostValue}
            handleDeleteRow={handleDeleteRow}
            setIsCheckboxChecked={setIsCheckboxChecked}
            isCheckboxChecked={isCheckboxChecked}
            setShippingCostValue={setShippingCostValue}
            handleAddRow={handleAddRow}
            handleScanSettingChange={handleScanSettingChange}
            rows={rows}
            isShowNewModal={isShowNewModal}
            settingModalSettingClose={settingModalSettingClose}
            shippingSettingUpdates={shippingSettingUpdates}
            shippingSettingsLoading={shippingSettingsLoading}
            shippingSettingsError={shippingSettingsError}
            setShippingSettingsError={setShippingSettingsError}
            shippingRoleError={shippingRoleError}
            setShippingRoleError={setShippingRoleError}
          />
          <ImageTooltip
            imageUrl={hoveredImage}
            show={!!hoveredImage}
            onClose={handleMouseLeave}
          />
          <KeepaChartTooltip
            imageUrl={isgraph}
            show={!!isgraph}
            onClose={handleMouseLeave}
            keepaImageCheck={keepaImageCheck}
          />
        </Container>
      )}
    </section>
  );
};

export default ParticularItem;
