import React from "react";
import Modal from "react-bootstrap/Modal";
import { IoMdClose } from "react-icons/io";
import Button from "react-bootstrap/Button";
import { Col, Form, Row } from "react-bootstrap";
const EmailChangeModal = ({
    handleEmailModalClose,
    emailModalShow,
    emailError,
    email,
    setEmail,
    emailLoading,
    handleUpdateEmail
}: any) => {
  return <div>
    <Modal
        show={emailModalShow}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal-filter"
      >
        <Modal.Header className="d-flex justify-content-between">
          <Modal.Title id="contained-modal-title-vcenter">
            Email Update
          </Modal.Title>
          <IoMdClose
            color="#fff"
            size={20}
            onClick={handleEmailModalClose}
            style={{ cursor: "pointer" }}
          />
        </Modal.Header>
        <Modal.Body>
          <Row className="d-flex gap-1 px-2 py-2">
            <Col lg={12} md={12} xs={12} className="mt-2">
              <Form.Group>
                <Form.Label>Email</Form.Label>
                <div className="position-relative">
                  <Form.Control
                    type="email"
                    placeholder="Enter Email Address"
                    value={email}
                    onChange={(e) =>
                        setEmail(e.target.value)
                    }
                  />
                 
                </div>
              </Form.Group>
              {
                emailError && !email && <div className="text-danger  text-start pt-1">
                    Email Address is required.
                </div>
              }
            </Col>
           
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleEmailModalClose} disabled={emailLoading}>
            Close
          </Button>
          <Button 
          onClick={handleUpdateEmail} 
          disabled={emailLoading}>
            {emailLoading ? "Updating..." : "Update"}
          </Button>
        </Modal.Footer>
      </Modal>
  </div>;
};

export default EmailChangeModal;
