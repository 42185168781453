import React, { useContext, useState } from "react";
import { SearchContext } from "../../../context/searchContext";
import { useNavigate } from "react-router-dom";
import { SearchNormal } from "../../../utils";
import { Tooltip } from "@mui/material";
import { useStyles } from "../../../helpers";
const SearchAgain = () => {
  const [inputValue, setInputValue] = useState("");
  const classes = useStyles();
  const navigate = useNavigate();
  const { setSearchValue } =
    useContext(SearchContext) ??
    (() => {
      throw new Error(
        "SearchContext is undefined. Ensure the component is within SearchState."
      );
    })();

  const updateSearch = () => {
    localStorage.setItem("ASINID", inputValue);
    setSearchValue(inputValue);
    navigate(`/overview?asin=${inputValue}`);
  };
  return (
    <div className="offers-contentsss d-flex gap-1 relative" id="again">
      <img src={SearchNormal} alt="image" className="search-icons" />
      <input
        type="search"
        className="input-group form-control inputs-search"
        placeholder="Search..."
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            updateSearch();
          }
        }}
        onChange={(e) => setInputValue(e.target.value)}
      />
      <Tooltip
        title={<div className="content-tooltip">Search</div>}
        placement="top"
        classes={{ tooltip: classes.tooltip }}
      >
        <button className="sellerss-btn" onClick={updateSearch}>
          Search
        </button>
      </Tooltip>
    </div>
  );
};

export default SearchAgain;
