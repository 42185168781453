import React, { useState } from "react";
import { Col, Accordion } from "react-bootstrap";
import {
  AmazonFill,
  Copy,
  Diamond,
  GoogleRe,
  NoteText,
  ZoomIn,
} from "../../../utils";
import { IoMdStar, IoIosStarOutline, IoIosStarHalf } from "react-icons/io";
import { Link } from "react-router-dom";
import { calculateStars, useStyles } from "../../../helpers";
import { Tooltip } from "@mui/material";
import Slider from "../../Slider";
import { HiTemplate } from "react-icons/hi";
interface ProductDetailsProps {
  productDetails: any;
  activeKey: any;
  handleToggle: any;
  setActiveKey: React.Dispatch<React.SetStateAction<any>>;
}
const Product = ({
  productDetails,
  activeKey,
  handleToggle,
}: ProductDetailsProps) => {
  const [imageSlider, setImageSlider] = useState(false);
  const rating = calculateStars(productDetails?.rating);
  const classes = useStyles();

  const handleCopy = (type: any) => {
    navigator.clipboard
      .writeText(type === "ASIN" ? productDetails?.asin : productDetails?.upc)
      .then(() => {})
      .catch((error) => {
        console.error("Error copying URL: ", error);
      });
  };
  return (
    <Accordion activeKey={activeKey?.productDetails === true ? "0" : "1"}>
      <Accordion.Item eventKey="0">
        <Accordion.Header onClick={() => handleToggle("productDetails")}>
          Product
        </Accordion.Header>
        {imageSlider && productDetails?.allImageUrl?.length && (
          <Slider
            images={productDetails?.allImageUrl}
            setImageSlider={setImageSlider}
          />
        )}
        <Accordion.Body className="accordion-product">
          {/* <Row className="position-relative "> */}
          <div className="d-flex">
            <Col lg={4} md={4} xs={4} className="p-0">
              <div className="product-image position-relative ps-1">
                <div
                  className="slider-magnifier-btn cursor-pointer"
                  role="button"
                  tabIndex={0}
                  onClick={() => setImageSlider(true)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" || e.key === " ") {
                      setImageSlider(true);
                    }
                  }}
                  aria-label="Open image magnifier"
                >
                  <img src={ZoomIn} alt="zoom-in" />
                </div>

                <div
                  role="button"
                  tabIndex={0}
                  onClick={() => setImageSlider(true)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" || e.key === " ") {
                      setImageSlider(true);
                    }
                  }}
                  aria-label="Click to open image slider"
                  style={{ display: "inline-block", cursor: "pointer" }}
                >
                  <img
                    src={productDetails?.imageURL ?? ""}
                    alt="Product image"
                  />
                </div>
              </div>
            </Col>

            <Col
              lg={8}
              md={8}
              xs={8}
              className="image-carousel detail-product-heading"
            >
              <div className="product-details">
                <Tooltip
                  title={
                    <div className="content-tooltip">
                      {productDetails?.title}
                    </div>
                  }
                  placement="top"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <h1 className="detail-heading">{productDetails?.title}</h1>
                </Tooltip>

                <div className="main-product-description">
                  by
                  <span className="main-product-description">
                    {productDetails?.manufacturer &&
                      " " + productDetails?.manufacturer + " "}
                  </span>
                  <span className="main-product-description">
                    | {productDetails?.category}
                  </span>
                </div>
                <div className="d-flex gap-1 align-items-center flex-wrap mt-1">
                  {productDetails?.rating > 0 ? (
                    <>
                      {Array.from({ length: rating?.fullStars }).map(
                        (item, idx) => (
                          <i key={`${item}-${idx}`}>
                            <IoMdStar className="yellow-star" />
                          </i>
                        )
                      )}
                      {rating?.hasQuarterStar && (
                        <i key="quarter-star" className="quarter-star">
                          <IoIosStarHalf className="yellow-star" />
                        </i>
                      )}
                      {rating?.hasHalfStar && (
                        <i key="half-star">
                          <IoIosStarHalf className="yellow-star" />
                        </i>
                      )}
                      {rating?.hasThreeQuarterStar && (
                        <i
                          key="three-quarter-star"
                          className="three-quarter-star"
                        >
                          <IoIosStarHalf className="yellow-star" />
                        </i>
                      )}
                      {Array.from({ length: rating?.emptyStars }).map(
                        (item, idx) => (
                          <i key={`${item}-${idx}`}>
                            <IoIosStarOutline className="yellow-star" />
                          </i>
                        )
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                  <span className="review-description pt-1">
                    {productDetails?.review ?? "No reviews yet"}
                  </span>
                </div>
                {productDetails?.asin && (
                  <div
                    role="button"
                    tabIndex={0}
                    onClick={() => handleCopy("ASIN")}
                    onKeyDown={(e) => {
                      if (e.key === "Enter" || e.key === " ") {
                        handleCopy("ASIN");
                      }
                    }}
                    className="cursor-pointer sas-choose-secondary secondary-text d-flex align-items-center gap-2"
                    aria-label="Copy UPC"
                  >
                    <span className="span-one d-flex align-items-center gap-1">
                      <Link to="">
                        <img src={Copy} alt="copy" />
                      </Link>
                      <p className="asin-desc m-0">ASIN:</p>
                    </span>
                    <p className="asin-explaination m-0">
                      {productDetails?.asin}
                    </p>
                  </div>
                )}
                {productDetails?.upc && (
                  <div
                    role="button"
                    tabIndex={0}
                    onClick={() => handleCopy("UPC")}
                    onKeyDown={(e) => {
                      if (e.key === "Enter" || e.key === " ") {
                        handleCopy("UPC");
                      }
                    }}
                    className="cursor-pointer sas-choose-secondary secondary-text d-flex align-items-center gap-2"
                    aria-label="Copy UPC"
                  >
                    <span className="span-one d-flex align-items-center gap-1">
                      <Link to="">
                        <img src={Copy} alt="copy" />
                      </Link>
                      <p className=" asin-desc m-0">UPC:</p>
                    </span>
                    <p className="asin-explaination m-0">
                      {productDetails?.upc}
                    </p>
                  </div>
                )}
                <div className="d-flex align-items-center gap-1 mt-2">
                  <Tooltip
                    title={
                      <div className="content-tooltip">
                        <strong>Package Dimensions</strong>
                        <br />
                        <span>
                          Width: {productDetails?.packageDimensions?.width}
                          <br />
                        </span>
                        <span>
                          Height: {productDetails?.packageDimensions?.height}
                          <br />
                        </span>
                        <span>
                          length: {productDetails?.packageDimensions?.length}
                          <br />
                        </span>
                        <span>
                          Weight: {productDetails?.packageDimensions?.weight}
                        </span>
                      </div>
                    }
                    placement="top"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <button
                      className="flex-button"
                      data-bs-toggle="tooltip"
                      data-bs-placement="bottom"
                    >
                      <img src={Diamond} alt="copy" className="w-full" />
                    </button>
                  </Tooltip>
                  <Tooltip
                    title={
                      <div className="content-tooltip">
                        <strong>Item Dimensions</strong>
                        <br />
                        <span>
                          Width: {productDetails?.itemDimensions?.width}
                          <br />
                        </span>
                        <span>
                          Height: {productDetails?.itemDimensions?.height}
                          <br />
                        </span>
                        <span>
                          length: {productDetails?.itemDimensions?.length}
                          <br />
                        </span>
                        <span>
                          Weight: {productDetails?.itemDimensions?.weight}
                        </span>
                      </div>
                    }
                    placement="top"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <button
                      className="flex-button"
                      data-bs-toggle="tooltip"
                      data-bs-placement="bottom"
                    >
                      <HiTemplate color="#fff" size={17} />
                    </button>
                  </Tooltip>
                  <Tooltip
                    title={
                      <div>
                        {productDetails?.aboutDetails?.map(
                          (detail: any, index: number) => (
                            <div
                              className="about-detailed content-tooltip"
                              key={`${detail}-${index}`}
                            >
                              {detail}
                              <br />
                              <br />
                            </div>
                          )
                        )}
                      </div>
                    }
                    placement="bottom"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <button
                      className="flex-button"
                      data-bs-toggle="tooltip"
                      data-bs-placement="bottom"
                    >
                      <img src={NoteText} alt="copy" className="w-full" />
                    </button>
                  </Tooltip>

                  <Tooltip
                    title={
                      <div className="content-tooltip">
                        Open Amazon product page
                      </div>
                    }
                    placement="top"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <a
                      className="flex-button"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      href={productDetails?.amzRedirect}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={AmazonFill} alt="copy" className="w-full" />
                    </a>
                  </Tooltip>
                  <Tooltip
                    title={
                      <div className="content-tooltip">
                        Op Search for product title on Google
                      </div>
                    }
                    placement="top"
                    className="content-tooltip"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <a
                      className="flex-button"
                      data-bs-toggle="tooltip"
                      data-bs-placement="bottom"
                      href={productDetails?.googleRedirect}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={GoogleRe} alt="copy" className="w-full" />
                    </a>
                  </Tooltip>
                </div>
              </div>
            </Col>
          </div>

          {/* </Row> */}
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default Product;
