import React from "react";
import { Link } from "react-router-dom";
import { Tooltip } from "@mui/material";
import { useStyles } from "../../../helpers";
interface EbayProps {
  data: any;
}
const Ebay = ({ data }: EbayProps) => {
  const classes = useStyles();
  return (
    <div className="offers-contentsss d-flex gap-2 flex-wrap " id="ebay">
      {data?.map((item: any, rowIndex: any) => (
         <Tooltip
         key={`${item}-${rowIndex}`}
         title={<div className="content-tooltip">{item.title}</div>}
         placement="top"
         classes={{ tooltip: classes.tooltip }}
       >
         <Link
          to={item.link}
          className="sellerss-btn"
          target={item.target}
          rel="noopener noreferrer"
        >
          {item.image && <img src={item.image} alt="ebay" />}
          {item.title}
        </Link>
       </Tooltip>
        
      ))}
    </div>
  );
};

export default Ebay;
