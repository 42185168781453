import React from "react";

const Toggler = () => {
  return (
    <div className="toggler">
      <input type="checkbox" className="checkbox-desc" id="toggleYesNo" />
      <label
        htmlFor="toggleYesNo"
        className="unchecked-label seller-description"
      >
        Yes
      </label>
      <label htmlFor="toggleYesNo" className="checked-label seller-description">
        No
      </label>
      <div className="background checked-background"></div>
      <div className="background unchecked-background"></div>
    </div>
  );
};

export default Toggler;
