import React, {
  createContext,
  useState,
  ReactNode,
  Dispatch,
  SetStateAction,
  useMemo,
} from "react";

interface ProfitContextValue {
  profit: number;
  setProfit: Dispatch<SetStateAction<number>>;
  salePrice: number;
  setSalePrice: Dispatch<SetStateAction<number>>;
}

export const ProfitContext = createContext<ProfitContextValue | undefined>(
  undefined
);

interface ProfitStateProps {
  children: ReactNode;
}

const ProfitState: React.FC<ProfitStateProps> = ({ children }) => {
  const [profit, setProfit] = useState(0);
  const [salePrice, setSalePrice] = useState(0);

  const contextValue = useMemo(
    () => ({ profit, setProfit, salePrice, setSalePrice }),
    [profit, salePrice]
  );

  return (
    <ProfitContext.Provider value={contextValue}>
      {children}
    </ProfitContext.Provider>
  );
};

export default ProfitState;
