import React from "react";
import Modal from "react-bootstrap/Modal";
import { IoMdClose } from "react-icons/io";
import Button from "react-bootstrap/Button";
import { Col, Form, Row } from "react-bootstrap";
const NameChangeModal = ({
  handleNameModalClose,
  nameModalShow,
  fullNameError,
  fullName,
  setFullName,
  fullNameLoading,
  handleUpdateName
}: any) => {
  return <div>
    <Modal
        show={nameModalShow}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal-filter"
      >
        <Modal.Header className="d-flex justify-content-between">
          <Modal.Title id="contained-modal-title-vcenter">
            Full Name Update
          </Modal.Title>
          <IoMdClose
            color="#fff"
            size={20}
            onClick={handleNameModalClose}
            style={{ cursor: "pointer" }}
          />
        </Modal.Header>
        <Modal.Body>
          <Row className="d-flex gap-1 px-2 py-2">
            <Col lg={12} md={12} xs={12} className="mt-2">
              <Form.Group>
                <Form.Label>Full Name</Form.Label>
                <div className="position-relative">
                  <Form.Control
                    type="text"
                    placeholder="Enter Full Name"
                    value={fullName}
                    onChange={(e) =>
                        setFullName(e.target.value)
                    }
                  />
                 
                </div>
              </Form.Group>
              {
                fullNameError && !fullName && <div className="text-danger  text-start pt-1">
                    Full Name is required.
                </div>
              }
            </Col>
           
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleNameModalClose} disabled={fullNameLoading}>
            Close
          </Button>
          <Button 
          onClick={handleUpdateName} 
          disabled={fullNameLoading}>
            {fullNameLoading ? "Updating..." : "Update"}
          </Button>
        </Modal.Footer>
      </Modal>
  </div>;
};

export default NameChangeModal;
