import { useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import DataTable from "../../Datatable";
import VariationsModalComponent from "./variationsModal";
import { fillerIcon, Listing, SearchNormal } from "../../../utils";
import { Tooltip } from "@mui/material";
import { useStyles } from "../../../helpers";
interface VariationsProps {
  variations?: any;
  columns: any;
  variationLoadings: any;
}

const Variations = ({
  variations,
  columns,
  variationLoadings,
}: VariationsProps) => {
  const classes = useStyles();
  const [sortDirection, setSortDirection] = useState("asc");
  const [sortedColumn, setSortedColumn] = useState<any>("");
  const [filteredData, setFilteredData] = useState(variations);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortedData, setSortedData] = useState(filteredData);
  const [showModal, setShowModal] = useState(false);
  const [visibleColumns, setVisibleColumns] = useState<any>({});

  useEffect(() => {
    if (columns && columns.length > 0) {
      const initialVisibleColumns = columns.reduce((acc: any, column: any) => {
        acc[column.id] = column.id !== "ASIN";
        return acc;
      }, {});
      setVisibleColumns(initialVisibleColumns);
    }
  }, [columns]);
  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleShowModal = () => {
    setShowModal(true);
  };
  useEffect(() => {
    if (searchTerm) {
      const filtered = (variations || []).filter((item: any) =>
        Object.values(item).some(
          (value) =>
            typeof value === "string" &&
            value.toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
      setFilteredData(filtered);
    } else {
      setFilteredData(variations || []);
    }
  }, [searchTerm, variations]);
  const handleSort = (column: any) => {
    const direction = sortDirection === "asc" ? "desc" : "asc";
    const sorted = [...filteredData].sort((a, b) => {
      let comparison = 0;
      
      if (a[column.id] > b[column.id]) {
        comparison = 1;
      } else if (a[column.id] < b[column.id]) {
        comparison = -1;
      }
    
      return direction === "asc" ? comparison : -comparison;
    });
    setSortDirection(direction);
    setSortedColumn(column.id);
    setFilteredData(sorted);
  };

  const handleToggleColumn = (columnId: string, event: any) => {
    event.stopPropagation();
    setVisibleColumns((prevState: any) => ({
      ...prevState,
      [columnId]: !prevState[columnId],
    }));
  };

  const filteredColumns = columns.filter(
    (column: any) => visibleColumns[column.id]
  );
  return (
    <div id="variations">
      {variationLoadings ? (
        <div className="loading-buyBox" style={{ overflow: "hidden" }}></div>
      ) : (
        <div className="variations-group">
          <div className="d-flex offers-contentsss justify-content-between align-items-center gap-2 ">
            <div className="relative flex-one">
              <img
                src={SearchNormal}
                alt="image"
                className="search-icon-second"
              />
              <input
                type="search"
                className="input-group form-control inputs-search bar-input"
                placeholder="Search"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            <div className="d-flex gap-1 align-items-center">
              <Dropdown>
                <Tooltip
                  title={<div className="content-tooltip">Columns</div>}
                  placement="top"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Dropdown.Toggle
                    className={`btn-refresh seller-btn filter-btn d-flex justify-content-center align-items-center ${
                      !filteredColumns.length && "cursor-not-allowed"
                    }`}
                    disabled={!filteredColumns.length}
                  >
                    <img
                      src={fillerIcon}
                      alt="filter"
                      className="analaysis-img"
                    />
                  </Dropdown.Toggle>
                </Tooltip>

                <Dropdown.Menu>
                  {columns.map((column: any) => (
                    <Dropdown.Item
                      key={column.id}
                      as="div"
                      className="d-flex align-items-center gap-2"
                    >
                      <input
                        type="checkbox"
                        checked={visibleColumns[column.id]}
                        onClick={(e) => e.stopPropagation()}
                        onChange={(e) => handleToggleColumn(column.id, e)}
                      />
                      {column.Header}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
              <Tooltip
                title={<div className="content-tooltip">Multiple Sort</div>}
                placement="top"
                classes={{ tooltip: classes.tooltip }}
              >
                <button
                  className={`btn-refresh ${
                    !filteredColumns.length
                      ? "analysis-btn-disable "
                      : "analysis-btn seller-btn"
                  }`}
                  onClick={() => handleShowModal()}
                  disabled={!filteredColumns.length}
                >
                  <img src={Listing} alt="listing" className="analaysis-img" />
                </button>
              </Tooltip>
            </div>
          </div>
          <DataTable
            data={filteredData}
            columns={filteredColumns}
            handleSort={handleSort}
            sortDirection={sortDirection}
            sortedColumn={sortedColumn}
            sortedData={sortedData}
            setSortedData={setSortedData}
            status="variation"
          />
        </div>
      )}

      <VariationsModalComponent
        showModal={showModal}
        handleCloseModal={handleCloseModal}
        handleSort={handleSort}
        filteredColumns={filteredColumns}
      />
    </div>
  );
};

export default Variations;
