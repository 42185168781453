import React from "react";
import { sellerscoutLogo } from "../../utils";
const Plan = () => {
  return (
    <div>
      <div className="plan-page text-center">
        <img
          src={sellerscoutLogo}
          alt="Seller Scout Logo"
          width={"420px"}
          height={"21px"}
          className="mb-4"
        />
        <h1 className="pt-4">Our Pricing Plans</h1>
        <h2 className="pt-5">
          coming soon<span className="dot">...</span>
        </h2>
      </div>
    </div>
  );
};

export default Plan;
