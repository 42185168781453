import React from "react";
import { Link } from "react-router-dom";
import { Tooltip } from "@mui/material";
import { useStyles } from "../../../helpers";
interface SellerCentralProps {
  data: any;
}
const SellerCentral = ({ data }: SellerCentralProps) => {
  const classes = useStyles();
  return (
    <div className="offers-contentsss d-flex gap-2 flex-wrap" id="amazon">
      {data?.map((item: any, rowIndex: any) =>
        item.type === "text" ? (
          <Tooltip
            title={
              <div className="content-tooltip">
                {item.title ? item.title : "Search Amazon"}
              </div>
            }
            placement="top"
            classes={{ tooltip: classes.tooltip }}
            key={`${item}-${rowIndex}`}
          >
            <Link to={item.link} target={item.target} className="sellerss-btn ">
              <img src={item.image} alt="icon" />
              {item.title}
            </Link>
          </Tooltip>
        ) : null
      )}
    </div>
  );
};

export default SellerCentral;
