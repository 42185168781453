import React from "react";
import Modal from "react-bootstrap/Modal";
import { IoMdClose } from "react-icons/io";
import Button from "react-bootstrap/Button";
import { Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
const UpgradePlanLinkModal = ({ isLimitExceeded, setIsLimitExceeded }: any) => {
  const navigate = useNavigate();
  return (
    <div>
      <Modal
        show={isLimitExceeded}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal-filter"
      >
        <Modal.Header className="d-flex justify-content-between">
          <Modal.Title id="contained-modal-title-vcenter">
            Plan Limit Exceeded
          </Modal.Title>
          <IoMdClose
            color="#fff"
            size={25}
            onClick={() => setIsLimitExceeded(false)}
            style={{ cursor: "pointer" }}
          />
        </Modal.Header>
        <Modal.Body>
          <Row className="text-center p-3">
            <h2 className="alert-message-modal">
              You have exceeded your current plan limit. We are showing demo
              data. Please upgrade your plan to continue using this feature.
            </h2>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setIsLimitExceeded(false)}>
            Close
          </Button>
          <Button
            onClick={() => {
              navigate("/subscriptions");
            }}
          >
            Upgrade Plan
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default UpgradePlanLinkModal;
