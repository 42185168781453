import React from "react";
import KeepaList from "./keepaList";
import { flagImages, valuesKeepa } from "../../../constants";
import { Dummy, RefreshOne } from "../../../utils";
import { Tooltip } from "@mui/material";
import { useStyles } from "../../../helpers";
interface KeepaProps {
  data?: any;
  selectedCountry: any;
  selectedDay: any;
  setSelectedCountry: any;
  setSelectedDay: any;
  loading: any;
  getKeepaValue?: any;
}

const Keepa = ({
  data,
  selectedCountry,
  selectedDay,
  setSelectedCountry,
  setSelectedDay,
  loading,
  getKeepaValue,
}: KeepaProps) => {
  const classes = useStyles();
  const handleCountrySelect = (item: any) => {
    getKeepaValue(item, selectedDay);
    setSelectedCountry(item);
  };

  const handleDaySelect = (item: any) => {
    getKeepaValue(selectedCountry, item);
    setSelectedDay(item);
  };
  return (
    <div id="keepa">
      <div className="offeres-out d-flex offers-contentsss gap-1 align-items-center">
        <p className="market-description mb-0 mt-0">Marketplaces:</p>
        <KeepaList
          data={flagImages}
          className="d-flex gap-1 align-items-center justify-content-start flex-wrap country-content-listing p-0"
          type="country"
          selectedItem={selectedCountry}
          onItemSelect={handleCountrySelect}
        />
      </div>

      {loading ? (
        <div
          className="loading-anim"
          style={{ overflow: "hidden", height: "160px" }}
        ></div>
      ) : (
        <img src={data ? data : Dummy} alt="" className="keepa-image" />
      )}
      <div className="d-flex gap-2 offers-contentsss align-items-center justify-content-between flex-wrap counter-borders">
        <div className="d-flex gap-2 align-items-center ">
          <p className="market-description mb-0 ">Days:</p>
          <div>
            <KeepaList
              className="d-flex gap-2 align-items-center justify-content-start flex-wrap p-0"
              data={valuesKeepa}
              selectedItem={selectedDay}
              onItemSelect={handleDaySelect}
            />
          </div>
        </div>
        <Tooltip
          title={<div className="content-tooltip">Refresh</div>}
          placement="top"
          classes={{ tooltip: classes.tooltip }}
        >
          <button className="btn-refresh  d-flex align-items-center justify-content-center">
            <img src={RefreshOne} alt="listing" className="analaysis-img" />
          </button>
        </Tooltip>
      </div>
    </div>
  );
};

export default Keepa;
