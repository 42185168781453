import React, { useContext, useEffect, useState } from "react";
import "./index.css";
import { SearchContext } from "../../context/searchContext";
import { getSubscriptionDetails } from "../../Service/services";
import Loader from "../Loader";
import PricingSlider from "../PricingSlider";
import PricingTab from "../PricingTab";

const Subscription: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { setSearchValue, setIsGobalFilter } =
    useContext(SearchContext) ??
    (() => {
      throw new Error(
        "SearchContext is undefined. Ensure the component is within SearchState."
      );
    })();
  useEffect(() => {
    setSearchValue("");
    setIsGobalFilter("");
  }, []);
  const getSubscription = async () => {
    try {
      await getSubscriptionDetails();
    } catch (error: any) {
      console.log("e", error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getSubscription();
  }, []);
  return (
    <section className="">
      {isLoading ? (
        <div className="loading-loader">
          <Loader />
        </div>
      ) : (
        <div className="subscription-container">
          {/* <div className="subscription-item">
            <div className="subscription-title d-flex justify-content-between align-items-center">
              <h2 className="mb-0"> Subscription Details</h2>
            </div>

            <div className="subscription-content ">
              <Form>
                <Row className="d-flex gap-1 px-3 pb-1 ">
                  <Col lg={12} md={12} xs={12}>
                    <Form.Group className="form-group-panel">
                      <Form.Label className="">Username</Form.Label>
                      <input
                        type="text"
                        className=" "
                        placeholder="Quick Info"
                        maxLength={50}
                        value={subscriptionDetail?.userName}
                        readOnly
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={12} md={12} xs={12}>
                    <Form.Group className="form-group-panel btn-input">
                      <Form.Label className=""> Plan</Form.Label>
                      <input
                        type="text"
                        className=" "
                        placeholder="SellerAmp SAS - Getting Serious"
                        maxLength={50}
                        value={subscriptionDetail?.plan}
                        readOnly
                      />
                      <div className="form-btns-panels">
                        <Button
                          buttonText={"Upgrade"}
                          buttonColor={"#FBBC31"}
                          buttonWidth="78px"
                        />
                      </div>
                    </Form.Group>
                  </Col>
                  <Col lg={12} md={12} xs={12}>
                    <Form.Group className="form-group-panel btn-input">
                      <Form.Label className="">Payment Card</Form.Label>
                      <div className="scan-month">
                      {formatCardNumber(subscriptionDetail.cardMaskedNumber)}
                      </div>
                      <input
                        type="text"
                        className=" "
                        placeholder="...2049 expire 6/2028"
                        maxLength={50}
                        value={subscriptionDetail?.cardMaskedNumber}
                        readOnly
                      />
                      <div className="form-btns-panels">
                        <Button
                          buttonText={"Update"}
                          buttonColor={"#FBBC31"}
                          buttonWidth="78px"
                        />
                      </div>
                    </Form.Group>
                  </Col>
                  <Col lg={12} md={12} xs={12}>
                    <Form.Group className="form-group-panel btn-input">
                      <Form.Label className="">Renews on</Form.Label>
                      <input
                        type="text"
                        className=" "
                        placeholder="2024-08-25"
                        maxLength={50}
                        value={
                          subscriptionDetail?.nextBilling
                            ? moment(subscriptionDetail?.nextBilling).format(
                                "MM-DD-YYYY"
                              )
                            : ""
                        }
                        readOnly
                      />
                      <div className="form-btns-panels">
                        <Button
                          buttonText={"Cancel"}
                          buttonColor={"#FBBC31"}
                          buttonWidth="78px"
                        />
                      </div>
                    </Form.Group>
                  </Col>
                  <Col lg={12} md={12} xs={12}>
                    <Form.Group className="form-group-panel">
                      <Form.Label className="">Member Since</Form.Label>
                      <input
                        type="text"
                        className=" "
                        placeholder="$ 27.95"
                        value={
                          subscriptionDetail?.createdOn
                            ? moment(subscriptionDetail.createdOn).format(
                                "MM-DD-YYYY"
                              )
                            : ""
                        }
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={12} md={12} xs={12}>
                    <Form.Group className="form-group-panel">
                      <Form.Label className="">Max Mobile Installs</Form.Label>
                      <input
                        type="text"
                        className=" "
                        placeholder="3"
                        maxLength={50}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={12} md={12} xs={12}>
                    <Form.Group className="form-group-panel">
                      <Form.Label className="">
                        Max Extension Installs
                      </Form.Label>
                      <input
                        type="text"
                        className=" "
                        placeholder="5"
                        maxLength={50}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={12} md={12} xs={12}>
                    <Form.Group className="form-group-panel">
                      <Form.Label className="">Analyzer This Month</Form.Label>
                      <div className="scan-month">
                        {subscriptionDetail.analyzerUsage} of{" "}
                        {subscriptionDetail.analyzerLimit == "-1"
                          ? "unlimited"
                          : subscriptionDetail.analyzerLimit}
                      </div>
                    </Form.Group>
                  </Col>
                  <Col lg={12} md={12} xs={12}>
                    <Form.Group className="form-group-panel">
                      <Form.Label className="">Scan This Month</Form.Label>
                      <div className="scan-month">
                        {subscriptionDetail.scannerUsage} of{" "}
                        {subscriptionDetail.scannerLimit == "-1"
                          ? "unlimited"
                          : subscriptionDetail.scannerLimit}
                      </div>
                    </Form.Group>
                  </Col>
                 
                </Row>
              </Form>
            </div>
          </div> */}

          <PricingSlider />
          <PricingTab />
        </div>
      )}
    </section>
  );
};

export default Subscription;
