import React, { useState } from "react";
import styles from "./index.module.css";
import popularTag from "../../Assests/popularTag.svg";
import { pricingData, subHeaderText } from "../../constants";
import {
  getActiveTabPrice,
  getBillingTabPrice,
  getPeriodTimeTabPrice,
  getPriceTabHeader,
  getPriceTabHeaderClass,
  getPriceTabHeaderScanner,
  getsubscriptionFreeActiveProduct,
  getsubscriptionFreePrice,
  tableCellClass,
} from "../../helpers/commanFunction";

const PricingTab: React.FC = () => {
  const [activeProduct, setActiveProduct] = useState<"analyzer" | "scanner">(
    "analyzer"
  );
  const [isAnnual, setIsAnnual] = useState(false);
  const currentPricing = pricingData[activeProduct];
  const prices = isAnnual
    ? currentPricing.pricing.annual
    : currentPricing.pricing.monthly;
  const currentSubHeader = subHeaderText[activeProduct];
  console.log("currentSubHeader", currentSubHeader);

  return (
    <section className={styles.pricingSection}>
      <div className={styles.container}>
        <h1 className={styles.mainHeading}>Choose Product & Plan Below</h1>

        <div className={styles.productButtons}>
          <button
            className={`${styles.productBtn} ${
              activeProduct === "analyzer" ? styles.activeBtn : ""
            }`}
            onClick={() => setActiveProduct("analyzer")}
          >
            Product Analyzer
          </button>
          <button
            className={`${styles.productBtn} ${
              activeProduct === "scanner" ? styles.activeBtn : ""
            }`}
            onClick={() => setActiveProduct("scanner")}
          >
            Bulk Scanner
          </button>
        </div>

        <div className={styles.pricingTable}>
          <div className={styles.tableHeader}>
            <div className={styles.headerCell}>
              <p className={styles.offerText}>
                <span className={styles.savingsText}>Save up to 30%</span> per
                month when billed annually
              </p>
              <div className={styles.billingToggle}>
                <div
                  role="switch"
                  aria-checked={isAnnual}
                  tabIndex={0}
                  className={styles.toggleSwitch}
                  onClick={() => setIsAnnual(!isAnnual)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" || e.key === " ") {
                      setIsAnnual(!isAnnual);
                    }
                  }}
                >
                  <div
                    className={`${styles.toggleKnob} ${
                      isAnnual ? styles.toggleRight : ""
                    }`}
                  />
                </div>
                <span className={styles.periodText}>
                  {getPeriodTimeTabPrice(isAnnual)}
                </span>
              </div>
            </div>
            {currentPricing.headers.map((header, index) => (
              <div
                key={`${header}-${index}`}
                className={`${styles.headerCell} ${
                  (activeProduct === "analyzer" && header === "Pro") ||
                  (activeProduct === "scanner" && header === "Professional")
                    ? styles.popularPlan
                    : ""
                }`}
              >
                {((activeProduct === "analyzer" && header === "Pro") ||
                  (activeProduct === "scanner" &&
                    header === "Professional")) && (
                  <img
                    src={popularTag}
                    alt="Most Popular"
                    className={styles.popularTag}
                  />
                )}
                <div className={styles.headerContent}>
                  <h3 className={styles.headerContenttitle}>{header}</h3>
                  {activeProduct == "analyzer" && (
                    <div className={styles.lookupText}>
                      {
                        currentPricing.lookups[
                          header as keyof typeof currentPricing.lookups
                        ]
                      }
                    </div>
                  )}

                  <div className={styles.priceContainer}>
                    {header !== "Free" ? (
                      <>
                        <div className={styles.price}>
                          {isAnnual
                            ? currentPricing.pricing.annual[
                                header.toLowerCase() as keyof typeof prices
                              ]
                            : currentPricing.pricing.monthly[
                                header.toLowerCase() as keyof typeof prices
                              ]}
                          <span className={styles.billingPeriod}>
                            {getBillingTabPrice(isAnnual)}
                          </span>
                        </div>
                        <div className={styles.billingTag}>
                          {getActiveTabPrice(isAnnual)}
                        </div>
                      </>
                    ) : (
                      <div className={styles.price}>
                        {getsubscriptionFreePrice(activeProduct)}
                      </div>
                    )}
                  </div>
                  <button className={getPriceTabHeaderClass(header, styles)}>
                    {activeProduct == "analyzer"
                      ? getPriceTabHeader(header)
                      : getPriceTabHeaderScanner(header)}
                  </button>
                </div>
              </div>
            ))}
          </div>

          {/* Subheader row */}
          <div className={styles.subHeaderRow}>
            <div className={`${styles.boldText}`}>{currentSubHeader.title}</div>
            {currentSubHeader.columns.map((text, index) => (
              <div
                key={`${text}-${index}`}
                className={`${styles.tableCell} upload-text`}
              >
                {getsubscriptionFreeActiveProduct(activeProduct, text, index)}
              </div>
            ))}
          </div>
          {currentPricing.features.map((feature, index) =>
            "type" in feature && feature.type === "section-header" ? (
              <div key={`${feature}-${index}`} className={styles.sectionHeader}>
                {feature.name}
              </div>
            ) : (
              <div key={`${feature}-${index}`} className={styles.pricingRow}>
                <div className={styles.tableCell}>{feature.name} </div>
                <div
                  className={`${styles.tableCell} ${tableCellClass(
                    activeProduct,
                    index
                  )}`}
                >
                  {activeProduct === "scanner" && index < 14 && (
                    <span className="pro-badge">Pro</span>
                  )}
                  {feature.free}
                </div>
                <div className={styles.tableCell}>{feature.pro}</div>
                <div className={styles.tableCell}>{feature.unlimited}</div>
              </div>
            )
          )}
        </div>
      </div>
    </section>
  );
};

export default PricingTab;
