// LookUp Details Function
export const getRoiValue = (roi: any) =>
  roi["roi"] != null ? `${parseFloat(roi["roi"])} %` : "0.00 %";
export const getRoiClass = (roi: any) => {
  let color = "#000";

  if (roi["profit"] > 0) {
    color = "#3c763d";
  } else if (roi["profit"] < 0) {
    color = "#a94442";
  }

  return color;
};
export const getRoiProfit = (roi: any) =>
  roi["profit"] != null ? parseFloat(roi["profit"]).toFixed(2) : "0.00";
export const getRoiSalePriceClass = (roi: any) => {
  let color = "#000";

  if (roi["salePrice"] > 0) {
    color = "#3c763d";
  } else if (roi["salePrice"] < 0) {
    color = "#a94442";
  }

  return color;
};
export const getRoiSaleProfitValue = (roi: any) =>
  roi["salePrice"] != null ? parseFloat(roi["salePrice"]).toFixed(2) : "0.00";
export const getRoiItemsValue = (roi: any, item: any) =>
  roi[item] != null ? parseFloat(roi[item]).toFixed(2) : "0.00";
export const getFbmClass = (name: any, fulfillment: any) => {
  if (name === "Amazon.com" && fulfillment === "FBA") {
    return "amz-10";
  } else if (fulfillment === "FBA") {
    return "FBA-10";
  } else {
    return "FBM-7";
  }
};
export const getOfferItemClass = (offer: any, item: any) =>
  offer[item] > 0 ? "#047647" : "#a94442";
export const getOfferItemValue = (offer: any, item: any) =>
  offer[item] ? parseFloat(offer[item]).toFixed(2) : "0.00";

export const OfferReviewCountValue = (reviewCount: any) =>
  reviewCount ? `(${reviewCount})` : "No reviews yet";

export const OfferFulfillmentValue = (fulfillment: any) =>
  fulfillment === "FBA"
    ? "Fulfilled by Amazon (Prime)"
    : "Fulfilled by Merchant";
export const OfferFulfillmentClass = (name: any, fulfillment: any) =>
  name === "Amazon.com" && fulfillment === "FBA" ? "dotted-border" : "";

export const OfferFulfillmentFBMClass = (offer: any) =>
  offer["fulfillment"] === "FBM" ? "pt-1" : "";

export const OfferFulfillmentPseudoValue = (
  name: any,
  fulfillment: any,
  offer: any
) => {
  if (name === "Amazon.com" && fulfillment === "FBA") {
    return "AMZ";
  } else if (fulfillment === "FBA") {
    return "FBA";
  } else {
    return offer["fulfillment"];
  }
};

// Keepa List Page Function
export const countryTitleValue = (item: any) =>
  item === "All" ? "Lifetime" : `Average ${item} Days`;

export const getDiscountClass = (
  type: any,
  selectedItem: any,
  itemKey: any,
  item: any
) => {
  if (type === "country" && selectedItem === itemKey) {
    return "btn-refresh";
  } else if (type !== "country" && selectedItem === item) {
    return "btn-refresh";
  } else {
    return "";
  }
};

export const getDiscountBtnClass = (
  type: any,
  selectedItem: any,
  itemKey: any,
  item: any
) => {
  if (type === "country" && selectedItem === itemKey) {
    return "discount-btn-refresh";
  } else if (type !== "country" && selectedItem === item) {
    return "btn-simple";
  } else {
    return "btn-simple";
  }
};

// alerts page function
export const getAmazonShareBuyBoxClass = (alerts: any) => {
  if (alerts?.amazonShareBuyBox?.includes("Only one seller has had BB")) {
    return "text-warning";
  } else if (alerts?.amazonShareBuyBox?.includes("Never on Listing")) {
    return "text-success";
  } else if (alerts?.amazonShareBuyBox?.includes("Possibly")) {
    return "text-success";
  } else {
    return "";
  }
};

export const getPrivateLabelClass = (alerts: any) => {
  if (
    alerts?.privateLabel?.includes(
      "This product has few historic sellers which is a sign it could be PL"
    )
  ) {
    return "text-warning";
  } else if (alerts?.privateLabel?.includes("Unlikely")) {
    return "text-success";
  } else {
    return "";
  }
};

export const getIPAnalysisClass = (alerts: any) => {
  if (alerts?.ipAnalysis?.includes("is known to have IP issues")) {
    return "text-danger";
  } else if (alerts?.ipAnalysis?.includes("No known IP issues")) {
    return "text-success";
  } else {
    return "";
  }
};

export const getSizeClass = (alerts: any) => {
  if (alerts?.size?.includes("Oversize - Small")) {
    return "text-warning";
  } else if (alerts?.size?.includes("Standard Size")) {
    return "text-success";
  } else if (alerts?.size?.includes("Over Size")) {
    return "text-danger";
  } else {
    return "";
  }
};

export const getHazmatClass = (alerts: any) =>
  alerts ? "text-danger" : "text-success";

export const getVariationsClass = (variations: any) =>
  variations == 0 ? "text-success" : "text-warning";

// Profit Calculator page

export const getactiveKeyValue = (key: any) => (key === true ? "0" : "1");

export const profitCalculatorProfit = (profit: any) =>
  profit > 0 ? "profile-text" : "profile-text-danger";

export const profitCalculatorSalePrice = (salePrice: any) => {
  if (salePrice === undefined || salePrice === null) {
    return "";
  }
  if (salePrice === 0) {
    return "0.00";
  }
  const salePriceStr = String(salePrice);
  if (salePriceStr.startsWith("0..00")) {
    return salePrice;
  }
  return salePriceStr.replace(/^0+/, "");
};
export const profitCalculatorCostPrice = (costPrice: any) =>
  costPrice != null ? parseFloat(costPrice).toFixed(2) : "0.00";

export const profitCalculatorRoiClass = (roi: any) =>
  roi > 0 ? "profile-text" : "profile-text-danger";

export const profitCalculatorReferralFeeTooltips = (referralFee: any) =>
  referralFee ? referralFee?.toFixed(2) : "-";

export const profitCalculatorReferralFeeValue = (referralFee: any) =>
  referralFee != null ? parseFloat(referralFee).toFixed(2) : "0.00";

export const profitCalculatorFulfilmentTypeValue = (fulfilmentType: any) =>
  fulfilmentType === "FBM" ? "FBM" : "FBA";

export const profitCalculatorFulfilmentFBMTypeValue = (
  fulfilmentType: any,
  fulfilment_FBM: any,
  fulfilment_FBA: any
) => {
  if (fulfilmentType === "FBM") {
    return fulfilment_FBM != null ? Number(fulfilment_FBM).toFixed(2) : "0.00";
  }

  return fulfilment_FBA != null ? Number(fulfilment_FBA).toFixed(2) : "0.00";
};

export const profitCalculatorClosingFee = (closingFee: any) =>
  closingFee != null ? parseFloat(closingFee).toFixed(2) : "0.00";

export const profitCalculatorFulfilmentTypeClass = (fulfilmentType: any) =>
  fulfilmentType === "FBM" ? "seller-description-text" : "ranks-description";

export const profitCalculatorStorageFeeValue = (storageFee: any) =>
  storageFee != null ? parseFloat(storageFee).toFixed(2) : "0.00";

export const profitCalculatorDiscount = (
  totalDiscount: any,
  costPrice: any,
  discount: any
) =>
  totalDiscount
    ? (((costPrice || 0) * totalDiscount) / 100)?.toFixed(2) || "0.00"
    : (discount || 0).toFixed(2) || "0.00";

export const profitCalculatorCost = (price: any, quantity: any) =>
  price != null && quantity != null ? (price * quantity).toFixed(2) : "0.00";

// scan page function

export const scanColumnIdType = (column: string) =>
  column === "idtype" ? 30 : 120;

const capitalizeFirstLetter = (str: string) =>
  str.charAt(0).toUpperCase() + str.slice(1);
export const scanTableColumn = (column: string) => {
  switch (column) {
    case "fileName":
      return "File Name";
    case "date":
      return "Date";
    case "numberOfRecords":
      return "Line Count";
    case "processedPercentage":
      return "Progress";
    case "countryCode":
      return "Market Place";
    case "enablePowerUpData":
      return "Enable Power Up Data";
    default:
      return capitalizeFirstLetter(column);
  }
};

//  datatable page function

export const datatbleClass = (
  sortedColumn: any,
  columnId: any,
  sortDirection: any
) => {
  if (sortedColumn === columnId) {
    return sortDirection === "asc" ? "sort-up" : "sort-down";
  }

  return "both";
};

export const datatbleTdClass = (isAmazonSeller: any, status: any) => {
  if (isAmazonSeller) {
    return "amazon-seller table-data";
  }
  if (status === "variation") {
    return "table-data-variation";
  }
  return "table-data";
};

export const getDataTableText = (
  CoulmnId: any,
  isAmazonSeller: any,
  sellerDataType: any,
  cell: any
) =>
  CoulmnId === "type" && isAmazonSeller && sellerDataType === "FBA" ? (
    <span>AMZ</span>
  ) : (
    cell.render("Cell")
  );

  export const getUploadScanClass = (column: any) => {
    if (column === "ImageURL") {
      return 400;
    } else if (column === "Title") {
      return 1200;
    } else {
      return 150;
    }
  };

// Quick Info Page
export const getBSRClass = (bsr: any) =>
  bsr ? "criteria-success" : "criteria-fail";

export const getBSRValue = (bsr: any, formatDealSize: any) =>
  bsr ? formatDealSize(bsr) : "Unknown";

export const getEstSaleClass = (estSales: any) =>
  estSales ? "criteria-info" : "criteria-zero";

export const getEstSaleValue = (estSales: any) =>
  estSales ? `${estSales}/mon` : "Unknown";

export const getMaxCostTooltipValue = (costPrice: any, maxCost: any) => {
  if (costPrice == 0) {
    return (
      <div>
        <span role="img" aria-label="information">ℹ️</span>
        Please enter cost price.
      </div>
    );
  } else if (costPrice > 0 && costPrice < maxCost) {
    return <div>👍 Your cost price meets your criteria.</div>;
  } else {
    return <div>👎 Your cost price does not meet your criteria.</div>;
  }
};


export const getMaxCostclass = (costPrice: any, maxCost: any) => {
  if (costPrice == 0) {
    return "criteria-info";
  } else if (costPrice <= maxCost) {
    return "criteria-success";
  } else {
    return "criteria-fail";
  }
};

export const getEligibleValue = (eligible: any) => (eligible ? eligible : "-");

export const getProfitTooltipValue = (costPrice: any, maxCost: any) =>
  costPrice < maxCost ? (
    <div>👍 This meets your minimum profit criteria.</div>
  ) : (
    <div>👎 This does not meet your minimum profit criteria.</div>
  );

export const getProfitClass = (costPrice: any, maxCost: any) =>
  costPrice > maxCost ? "criteria-fail" : "criteria-success";

export const getRoiTooltipValue = (costPrice: any, maxCost: any) => {
  if (costPrice == 0) {
    return (
      <div>
        <span role="img" aria-label="information">ℹ️</span>
        Enter a cost price to calculate your ROI.
      </div>
    );
  } else if (costPrice <= maxCost) {
    return <div>👍 This meets your minimum ROI criteria.</div>;
  } else {
    return <div>👎 This does not meet your minimum ROI criteria.</div>;
  }
};


export const getBreakevenTooltipValue = (
  costPrice: any,
  salePrice: any,
  breakeven: any
) => {
  if (costPrice == 0) {
    return (
      <div>
        <span role="img" aria-label="information">ℹ️</span>
        Required sale price to breakeven.
      </div>
    );
  } else if (salePrice >= breakeven) {
    return <div>👍 Your sale price is equal to or above breakeven.</div>;
  } else {
    return <div>👎 Your sale price is below breakeven.</div>;
  }
};


export const getBreakevenClass = (
  costPrice: any,
  salePrice: any,
  breakeven: any
) => {
  if (costPrice == 0) {
    return "criteria-info";
  } else if (salePrice >= breakeven) {
    return "criteria-success";
  } else {
    return "criteria-fail";
  }
};


export const getRankAndPriceValue = (key: any, value: any, formatPrice: any) => {
  if (key === "BSR") {
    return value?.toLocaleString() || "0";
  } else if (value >= 0) {
    return `$ ${formatPrice(value)}`;
  } else {
    return formatPrice(value);
  }
};


export const getPriceTabHeader = (key: string) =>
  key === "Free" ? "CURRENT PLAN" : "GET STARTED";

export const getPriceTabHeaderScanner = (key: string) =>
  key === "Free" ? "CURRENT" : "SUBSCRIBE";

export const getPriceTabHeaderClass = (header: string, styles: any) =>
  header === "Free" ? styles.currentPlanBtn : styles.getStartedBtn;

export const getActiveTabPrice = (isAnnual: any) =>
  isAnnual ? "Billed yearly" : "Billed monthly";
export const getBillingTabPrice = (isAnnual: any) => (isAnnual ? "/yr" : "/mo");

export const getPeriodTimeTabPrice = (isAnnual: any) =>
  isAnnual ? "See monthly pricing" : "See annual pricing";

export const getsubscriptionFreePrice = (activeProduct: string) =>
  activeProduct === "scanner" ? "$0" : "";

export const getsubscriptionFreeActiveProduct = (
  activeProduct: string,
  text: string,
  index: any
) =>
  activeProduct == "scanner" && index == 0 ? (
    <div className="gap-4">
      {text} <span className="pro-badge-scanner ms-1">Pro</span>
    </div>
  ) : (
    text
  );

export const tableCellClass = (activeProduct: string, index: any) =>
  activeProduct === "scanner" && index < 14 ? "pro-cell" : "";
