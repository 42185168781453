import React, { useContext, useEffect, useState } from "react";
import { Tooltip } from "@mui/material";
import { calculateTotalFees, useStyles } from "../../../helpers";
import { ProfitContext } from "../../../context/ProfitContext";
import { IoMdStar, IoIosStarOutline, IoIosStarHalf } from "react-icons/io";
import {
  getFbmClass,
  getOfferItemClass,
  getOfferItemValue,
  getRoiClass,
  getRoiItemsValue,
  getRoiProfit,
  getRoiSalePriceClass,
  getRoiSaleProfitValue,
  getRoiValue,
  OfferFulfillmentClass,
  OfferFulfillmentFBMClass,
  OfferFulfillmentPseudoValue,
  OfferFulfillmentValue,
  OfferReviewCountValue,
} from "../../../helpers/commanFunction";

interface OffersProps {
  data: any;
  heading: any;
  type?: any;
  value?: any;
  typeclass?: string;
  productDetails?: any;
}

const LookUp = ({
  data,
  heading,
  type,
  value,
  typeclass,
  productDetails,
}: OffersProps) => {
  const classes = useStyles();
  const [roi, setRoi] = useState(25);
  const { profit, setProfit, salePrice, setSalePrice } =
    useContext(ProfitContext) ??
    (() => {
      throw new Error(
        "ProfitContext is undefined. Ensure the component is within SearchState."
      );
    })();
  
  const handleRoiChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRoi(parseFloat(e.target.value));

    const costPrice = productDetails?.quickInfo?.costPrice;
    const numericCostPrice =
      typeof costPrice === "number" ? costPrice : parseFloat(costPrice);
    const totalFees = calculateTotalFees(
      productDetails?.profitCalculator?.totalFees
    );
    const profit = (numericCostPrice * parseFloat(e.target.value) || 0) / 100;
    setProfit(profit || 0);
    const salePrice = numericCostPrice + totalFees + profit;
    setSalePrice(salePrice);
  };

  const handleProfitChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setProfit(parseFloat(e.target.value) || 0);

    const costPrice = productDetails?.quickInfo?.costPrice;
    const numericCostPrice =
      typeof costPrice === "number" ? costPrice : parseFloat(costPrice);
    const totalFees = calculateTotalFees(
      productDetails?.profitCalculator?.totalFees
    );
    const salePrice =
      numericCostPrice + totalFees + parseFloat(e.target.value) || 0;
    setSalePrice(salePrice);
  };

  useEffect(() => {
    if (productDetails) {
      const costPrice = productDetails?.quickInfo?.costPrice;
      const numericCostPrice =
        typeof costPrice === "number" ? costPrice : parseFloat(costPrice);

      const totalFees = calculateTotalFees(
        productDetails?.profitCalculator?.totalFees
      );
      const salePrice = numericCostPrice + totalFees + profit;
      setSalePrice(salePrice);
    }
  }, [productDetails]);
  const renderOfferItem = (
    item: any,
    offer: any,
    fullStars: any,
    hasQuarterStar: any,
    hasHalfStar: any,
    hasThreeQuarterStar: any,
    emptyStars: any
  ) => {
    if (item === "seller") {
      return (
        <div
          className={`text-bubble ${getFbmClass(
            offer.name,
            offer?.fulfillment
          )}`}
        >
          <Tooltip
            title={
              <div>
                <div style={{ fontSize: "13px" }}>{offer["name"]}</div>
                <div
                  className="d-flex gap-1 align-items-center flex-wrap"
                  style={{ fontSize: "13px" }}
                >
                  {offer?.rating > 0 ? (
                    <>
                      {Array.from({ length: fullStars }).map((item, idx) => (
                        <i key={`${item}-${idx}`}>
                          <IoMdStar className="rating-stars" />
                        </i>
                      ))}
                      {hasQuarterStar && (
                        <i key="quarter-star" className="quarter-star">
                          <IoIosStarHalf className="rating-stars" />
                        </i>
                      )}
                      {hasHalfStar && (
                        <i key="half-star">
                          <IoIosStarHalf className="rating-stars" />
                        </i>
                      )}
                      {hasThreeQuarterStar && (
                        <i
                          key="three-quarter-star"
                          className="three-quarter-star"
                        >
                          <IoIosStarHalf className="rating-stars" />
                        </i>
                      )}
                      {Array.from({ length: emptyStars }).map((item, idx) => (
                        <i key={`${item}-${idx}`}>
                          <IoIosStarOutline className="rating-stars" />
                        </i>
                      ))}
                    </>
                  ) : null}
                  <span className="rating-count reviews">
                    {OfferReviewCountValue(offer?.reviewCount)}
                  </span>
                </div>
                <span style={{ fontSize: "11px" }}>
                  {OfferFulfillmentValue(offer?.fulfillment)}
                </span>
              </div>
            }
            placement="top"
            classes={{ tooltip: classes.tooltip }}
          >
            <div
              className={`${OfferFulfillmentClass(
                offer.name,
                offer?.fulfillment
              )} cursor-pointer ${OfferFulfillmentFBMClass(offer)}`}
            >
              <span className="pseudolink">
                {OfferFulfillmentPseudoValue(
                  offer.name,
                  offer?.fulfillment,
                  offer
                )}
              </span>
            </div>
          </Tooltip>
        </div>
      );
    }

    if (item === "price" || item === "profit") {
      return (
        <span
          style={{ color: getOfferItemClass(offer, item) }}
        >{`$ ${getOfferItemValue(offer, item)}`}</span>
      );
    }

    if (item === "roi" && !offer?.roi) {
      return <span style={{ color: "#047647" }}>∞%</span>;
    }

    if (item === "roi" && offer?.roi) {
      return (
        <span
          style={{ color: getOfferItemClass(offer, item) }}
        >{`${offer[item]}%`}</span>
      );
    }

    return offer[item];
  };
  const renderTableRows = (type: any, data: any, heading: any) => {
    switch (type) {
      case "offer":
        return data?.map((offer: any, rowIndex: any) => {
          const fullStars = Math.floor(offer?.rating);
          const fractionalPart = offer?.rating % 1;
          let hasQuarterStar = false;
          let hasHalfStar = false;
          let hasThreeQuarterStar = false;
  
          if (fractionalPart >= 0.75) {
            hasThreeQuarterStar = true;
          } else if (fractionalPart >= 0.5) {
            hasHalfStar = true;
          } else if (fractionalPart >= 0.25) {
            hasQuarterStar = true;
          }
  
          const emptyStars =
            5 -
            fullStars -
            (hasQuarterStar ? 1 : 0) -
            (hasHalfStar ? 1 : 0) -
            (hasThreeQuarterStar ? 1 : 0);
  
          return (
            <tr key={`${offer}-${rowIndex}`}>
              <th scope="row">{rowIndex != null ? rowIndex + 1 : "-"}</th>
              {heading?.map((item: any, colIndex: any) => (
                <td key={`${item}-${colIndex}`}>
                  {renderOfferItem(
                    item,
                    offer,
                    fullStars,
                    hasQuarterStar,
                    hasHalfStar,
                    hasThreeQuarterStar,
                    emptyStars
                  )}
                </td>
              ))}
            </tr>
          );
        });
  
      case "roi":
        return data?.map((roi: any, rowIndex: any) => (
          <tr key={`${roi}-${rowIndex}`}>
            <th scope="row">{rowIndex != null ? rowIndex + 1 : "-"}</th>
            {heading?.map((item: any, colIndex: any) => {
              switch (item) {
                case "R.O.I.":
                  return <td key={`${item}-${colIndex}`}>{getRoiValue(roi)}</td>;
                case "Profit":
                  return (
                    <td key={`${item}-${colIndex}`}>
                      <span style={{ color: getRoiClass(roi) }}>
                        ${getRoiProfit(roi)}
                      </span>
                    </td>
                  );
                case "Sale Price":
                  return (
                    <td key={`${item}-${colIndex}`}>
                      <span style={{ color: getRoiSalePriceClass(roi) }}>
                        ${getRoiSaleProfitValue(roi)}
                      </span>
                    </td>
                  );
                default:
                  return (
                    <td key={`${item}-${colIndex}`}>
                      {getRoiItemsValue(roi, item)}
                    </td>
                  );
              }
            })}
          </tr>
        ));
  
      case "lookup":
        return data?.map((lookup: any, rowIndex: any) => (
          <tr key={`${lookup}-${rowIndex}`}>
            <th scope="row">{""}</th>
            {heading?.map((item: any, colIndex: any) => (
              <td key={`${item}-${colIndex}`}>{lookup[item]}</td>
            ))}
          </tr>
        ));
  
      default:
        return data?.map((marketimages: any, rowIndex: any) => (
          <tr key={`${marketimages}-${rowIndex}`}>
            <th scope="row">
              <img src={marketimages.src} alt="" />
            </th>
            {heading?.map((item: any, colIndex: any) => (
              <td key={`${item}-${colIndex}`}>{marketimages[item]}</td>
            ))}
          </tr>
        ));
    }
  };
  return (
    <div className={typeclass ? typeclass : "offers-content"}>
      <div className="main-table-section">
        <table className="table offers-table table-striped table-bordered offer-content-simple mb-0">
          <thead>
            <tr>
              {value === "value" && <th></th>}

              {heading?.map((item: any, index: any) => (
                <th key={`${item}-${index}`}>{item}</th>
              ))}
            </tr>
            {type === "roi" ? (
              <tr>
                <td className=""></td>
                <td>
                  <div className="input-group">
                    <input
                      type="number"
                      className=" form-control money-input not-money-input"
                      name="roi"
                      value={roi}
                      step="any"
                      pattern="[0-9.]*"
                      inputMode="decimal"
                      onChange={handleRoiChange}
                    />
                    <span className="input-group-addon focus-next-input home-mp-currency-symbol boxx-money-control">
                      %
                    </span>
                  </div>
                </td>
                <td>
                  <div className="input-group">
                    <span className="input-group-addon focus-next-input home-mp-currency-symbol">
                      $
                    </span>
                    <input
                      type="text"
                      className=" form-control  money-input"
                      name="profit"
                      value={Number(profit).toFixed(2)}
                      step="any"
                      pattern="[0-9.]*"
                      inputMode="decimal"
                      onChange={handleProfitChange}
                    />
                  </div>
                </td>
                <td>
                  <div className="input-group">
                    <span className="input-group-addon focus-next-input home-mp-currency-symbol ">
                      $
                    </span>
                    <input
                      type="text"
                      className=" form-control  money-input"
                      name="salePrice"
                      value={Number(salePrice).toFixed(2)}
                      step="any"
                      pattern="[0-9.]*"
                      inputMode="decimal"
                      disabled={true}
                    />
                  </div>
                </td>
              </tr>
            ) : (
              <></>
            )}
          </thead>
          <tbody>
          {renderTableRows(type, data, heading)}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default LookUp;
